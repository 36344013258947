import React from "react";

const ArrowIcon = () => {
  return (
    <svg
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6848 4.99986L10.689 5.00005C10.689 5.00005 6.60206 5.15742 6.60206 9.40982M10.6848 4.99986C10.5374 4.99272 6.60189 4.76295 6.60189 0.590149M10.6848 4.99986L0 4.99986M10.6848 4.99986H10.8466"
        stroke="#11190C"
        stroke-width="1.5"
      />
    </svg>
  );
};

export default ArrowIcon;
