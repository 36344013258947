import React, { useState } from "react";
import LogoIcon from "../../assets/icons/LogoIcon";
import HamBurgerIcon from "../../assets/icons/HamBurgerIcon";
import aawazWhiteLogo from "../../assets/images/aawazWhiteLogo.png";
import logoOrig from "../../assets/images/logoOrig.png";
import styles from "./Navbar.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import HamburgerWhite from "../../assets/icons/HamburgerWhite";
import LogoIconWhite from "../../assets/icons/LogoIconWhite";
import DownArrowIcon from "../../assets/icons/DownArrowIcon";
import ArrowIcon from "../../assets/icons/ArrowIcon";
import ChevronIcon from "../../assets/icons/ChevronIcon";

const Navbar = ({ isScrolledToDiv }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [isSubMenuOpenProducts, setIsSubMenuOpenProducts] = useState(false);
  const [isSubMenuOpenUsers, setIsSubMenuOpenUsers] = useState(false);
  const [isSubMenuOpenAbout, setIsSubMenuOpenAbout] = useState(false);

  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div
      className={`${styles.mainCont} ${
        isScrolledToDiv ? styles.mainContBlueBg : ""
      }`}
    >
      {
        isScrolledToDiv ? (
          <LogoIconWhite />
        ) : // <img className={styles.aawazWhiteLogo} src={aawazWhiteLogo} alt="" />
        isMenuOpen ? (
          ""
        ) : (
          <LogoIcon />
        )
        // <img className={styles.aawazWhiteLogo} src={logoOrig} alt="" />
      }
      <div className={styles.navAndBtns}>
        <ul className={styles.navLinks}>
          <li
            onMouseEnter={() => {
              setIsSubMenuOpenProducts(true);
              setIsSubMenuOpenUsers(false);
              setIsSubMenuOpenAbout(false);
            }}
            // onMouseLeave={() => setIsSubMenuOpenProducts(false)}
          >
            <NavLink
              // to="/products"
              className={`${styles.navLink} ${
                isScrolledToDiv ? styles.navLinkBlueBg : ""
              }`}
              activeClassName={styles.activeLink}
            >
              Products
            </NavLink>

            {isSubMenuOpenProducts && (
              <ul
                className={`${styles.subMenuProducts}  ${
                  isScrolledToDiv ? styles.subMenuProductsBlue : ""
                }`}
                // onMouseEnter={() => setIsSubMenuOpenProducts(true)} // Keep submenu open when hovering over it
                onMouseLeave={() => setIsSubMenuOpenProducts(false)}
              >
                <li>
                  <NavLink
                    className={`${styles.subNavLink}`}
                    to="/products/bootcamp"
                  >
                    Bootcamp
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={`${styles.subNavLink}`}
                    to="/products/inner-compass"
                  >
                    Inner Compass
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={`${styles.subNavLink}`}
                    to="/products/amal-bot"
                  >
                    AMAL Bot
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={`${styles.subNavLink}`}
                    to="/products/circle"
                  >
                    Circle
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={`${styles.subNavLink}`}
                    to="/products/credit"
                  >
                    Credit
                  </NavLink>
                </li>
              </ul>
            )}
          </li>

          <li
            onMouseEnter={() => {
              setIsSubMenuOpenProducts(false);
              setIsSubMenuOpenUsers(true);
              setIsSubMenuOpenAbout(false);
            }}
            // onMouseLeave={() => setIsSubMenuOpenUsers(false)}
          >
            <NavLink
              // to="/users"
              className={`${styles.navLink} ${
                isScrolledToDiv ? styles.navLinkBlueBg : ""
              }`}
              activeClassName={styles.activeLink}
            >
              Users
            </NavLink>

            {isSubMenuOpenUsers && (
              <ul
                className={`${styles.subMenuUsers}  ${
                  isScrolledToDiv ? styles.subMenuUsersBlue : ""
                }`}
                onMouseLeave={() => setIsSubMenuOpenUsers(false)}
              >
                <li>
                  <NavLink
                    className={`${styles.subNavLink}`}
                    to="/users/aspiring-entrepreneurs"
                  >
                    Aspiring Entrepreneurs
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={`${styles.subNavLink}`}
                    to="/users/startup-founders"
                  >
                    Startup Founders
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={`${styles.subNavLink}`}
                    to="/users/growth-stage-entrepreneurs"
                  >
                    Growth Stage Entrepreneurs
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    className={`${styles.subNavLink}`}
                    to="/users/emerging-business-leaders"
                  >
                    Emerging Business Leaders
                  </NavLink>
                </li>
              </ul>
            )}
          </li>

          <li>
            <NavLink
              to="/financial-institutions"
              // className={styles.navLink}
              className={`${styles.navLink} ${
                isScrolledToDiv ? styles.navLinkBlueBg : ""
              }`}
              activeClassName={styles.activeLink}
            >
              Financial Institutions
            </NavLink>
          </li>

          <li
            onMouseEnter={() => {
              setIsSubMenuOpenProducts(false);
              setIsSubMenuOpenUsers(false);
              setIsSubMenuOpenAbout(true);
            }}
            // onMouseLeave={() => setIsSubMenuOpenAbout(false)}
          >
            <NavLink
              // to="/about-us"
              className={`${styles.navLink} ${
                isScrolledToDiv ? styles.navLinkBlueBg : ""
              }`}
              activeClassName={styles.activeLink}
            >
              About Us
            </NavLink>
            {isSubMenuOpenAbout && (
              <ul
                className={`${styles.subMenuAbout}  ${
                  isScrolledToDiv ? styles.subMenuAboutBlue : ""
                }`}
                onMouseLeave={() => setIsSubMenuOpenAbout(false)}
              >
                <li>
                  <NavLink
                    className={`${styles.subNavLink} `}
                    to="/about-us/our-values"
                  >
                    Our Values
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
        </ul>
        <div className={styles.btnsCont}>
          <button
            className={`${styles.loginBtn} ${
              isScrolledToDiv ? styles.loginBtnBlueBg : ""
            }`}
            onClick={() => navigate("./login-cards")}
          >
            Login
          </button>
          <button className={styles.createBtn}>Create An Account</button>
        </div>
      </div>

      {/* //////////////////////////////////MOBILE NAV */}
      <div style={{ width: isMenuOpen ? "100%" : "" }}>
        <div className={styles.hamBtnWrap} onClick={toggleMenu}>
          {isScrolledToDiv ? <HamburgerWhite /> : <HamBurgerIcon />}
        </div>
        {isMenuOpen && (
          <div className={styles.mobileMenu}>
            <ul className={styles.navLinks}>
              <li
                onClick={() => {
                  setIsSubMenuOpenProducts(!isSubMenuOpenProducts);
                  setIsSubMenuOpenUsers(false);
                  setIsSubMenuOpenAbout(false);
                }}
                className={styles.navLinkMobile}
              >
                <NavLink
                  // to="/products"
                  className={`${styles.navLink} ${
                    isScrolledToDiv ? styles.navLinkBlueBg : ""
                  }`}
                  activeClassName={styles.activeLink}
                >
                  Products
                </NavLink>
                <ChevronIcon />

                {isSubMenuOpenProducts && (
                  <ul
                    className={`${styles.subMenuProducts} ${
                      isSubMenuOpenProducts ? styles.subMenuOpen : ""
                    } ${isScrolledToDiv ? styles.subMenuProductsBlue : ""}`}
                  >
                    <li>
                      <NavLink
                        className={`${styles.subNavLink}`}
                        to="/products/bootcamp"
                      >
                        Bootcamp
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={`${styles.subNavLink}`}
                        to="/products/inner-compass"
                      >
                        Inner Compass
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={`${styles.subNavLink}`}
                        to="/products/amal-bot"
                      >
                        AMAL Bot
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={`${styles.subNavLink}`}
                        to="/products/circle"
                      >
                        Circle
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={`${styles.subNavLink}`}
                        to="/products/credit"
                      >
                        Credit
                      </NavLink>
                    </li>
                  </ul>
                )}
              </li>

              <li
                onClick={() => {
                  setIsSubMenuOpenProducts(false);
                  setIsSubMenuOpenUsers(!isSubMenuOpenUsers);
                  setIsSubMenuOpenAbout(false);
                }}
                className={styles.navLinkMobile}
              >
                <NavLink
                  // to="/users"
                  className={`${styles.navLink} ${
                    isScrolledToDiv ? styles.navLinkBlueBg : ""
                  }`}
                  activeClassName={styles.activeLink}
                >
                  Users
                </NavLink>
                <ChevronIcon />

                {isSubMenuOpenUsers && (
                  <ul
                    className={`${styles.subMenuUsers} ${
                      isSubMenuOpenUsers ? styles.subMenuOpen : ""
                    } ${isScrolledToDiv ? styles.subMenuUsersBlue : ""}`}
                  >
                    <li>
                      <NavLink
                        className={`${styles.subNavLink}`}
                        to="/users/aspiring-entrepreneurs"
                      >
                        Aspiring Entrepreneurs
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={`${styles.subNavLink}`}
                        to="/users/startup-founders"
                      >
                        Startup Founders
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={`${styles.subNavLink}`}
                        to="/users/growth-stage-entrepreneurs"
                      >
                        Growth Stage Entrepreneurs
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        className={`${styles.subNavLink}`}
                        to="/users/emerging-business-leaders"
                      >
                        Emerging Business Leaders
                      </NavLink>
                    </li>
                  </ul>
                )}
              </li>

              <li>
                <NavLink
                  to="/financial-institutions"
                  // className={styles.navLink}
                  className={`${styles.navLink} ${
                    isScrolledToDiv ? styles.navLinkBlueBg : ""
                  }`}
                  activeClassName={styles.activeLink}
                >
                  Financial Institutions
                </NavLink>
              </li>

              <li
                onClick={() => {
                  setIsSubMenuOpenProducts(false);
                  setIsSubMenuOpenUsers(false);
                  setIsSubMenuOpenAbout(!isSubMenuOpenAbout);
                }}
                className={styles.navLinkMobile}
              >
                <NavLink
                  // to="/about-us"
                  className={`${styles.navLink} ${
                    isScrolledToDiv ? styles.navLinkBlueBg : ""
                  }`}
                  activeClassName={styles.activeLink}
                >
                  About Us
                </NavLink>
                <ChevronIcon />
                {isSubMenuOpenAbout && (
                  <ul
                    className={`${styles.subMenuAbout} ${
                      isSubMenuOpenAbout ? styles.subMenuOpen : ""
                    } ${isScrolledToDiv ? styles.subMenuAboutBlue : ""}`}
                  >
                    <li>
                      <NavLink
                        className={`${styles.subNavLink} `}
                        to="/about-us/our-values"
                      >
                        Our Values
                      </NavLink>
                    </li>
                  </ul>
                )}
              </li>
            </ul>
            <div className={styles.btnsCont}>
              <button
                className={`${styles.loginBtn} ${
                  isScrolledToDiv ? styles.loginBtnBlueBg : ""
                }`}
                onClick={() => navigate("./login-cards")}
              >
                Login
              </button>
              <button className={styles.createBtn}>Create An Account</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
