import React, { useState } from "react";
import styles from "./JoinWeAscend.module.css";
import Navbar from "../../components/navbar/Navbar";
import jwa1 from "../../assets/images/jwa1.png";
import DownArrowIcon from "../../assets/icons/DownArrowIcon";
import Footer from "../../components/footer/Footer";
import FooterB from "../../components/footerB/FooterB";
import Modal from "react-modal";
import close from "../../assets/images/close.png";
import subImg from "../../assets/images/subImg.png";

const JoinWeAscend = () => {
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  // console.log("apiBaseURL", apiBaseURL);

  const handleSubscription = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(
        `${apiBaseURL}/Subscribers/subscribe/postEmail`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      console.log("response", response);

      if (response?.status === 200) {
        setShowModal(true);
        setEmail("");
      } else {
        throw new Error("Failed to subscribe");
      }
    } catch (error) {
      // Handle error, e.g., show an error message
      console.error("Failed to subscribe:", error?.message);
      alert(error?.message);
    } finally {
      setLoading(false); // Reset loading state regardless of success or failure
    }
  };

  return (
    <>
      <Navbar />
      <div className={styles.mainCont}>
        <div className={styles.sec1}>
          <img src={jwa1} className={styles.jwa1} alt="" />
          <p className={styles.sorryText}>
            Sorry, WeAscend isn’t available in your region yet, but we’re on our
            way!
          </p>
          <div className={styles.accessIconCont}>
            <span className={styles.accessText}>Get early access</span>
            <DownArrowIcon />
          </div>
        </div>

        <div className={styles.sec2}>
          <p className={styles.joinPara}>
            If you’re excited to be among the first to experience our platform
            in your area, leave us your email. You’ll secure your spot on our
            exclusive early user waiting list. Don’t miss out on being a part of
            the ascent.
          </p>

          <form className={styles.emailSubCont} onSubmit={handleSubscription}>
            <div className={styles.emailSub1}>
              <input
                type="email"
                placeholder="Enter your email"
                className={styles.input}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className={styles.emailSub2}>
              <button type="submit" className={styles.subBtn}>
                Get early access
              </button>
            </div>
          </form>

          {loading && <div className={styles.loader}></div>}

          <Modal
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            contentLabel="Correct Input Data Modal"
            className={styles.modal}
          >
            <div className={styles.modalCont}>
              <div
                className={styles.crossIconCont}
                onClick={() => setShowModal(false)}
              >
                <img src={close} alt="" />
              </div>
              <div className={styles.sureCont}>
                <div className={styles.sureTextCont}>
                  <p>Welcome Aboard the Aawaz Community!</p>
                </div>
              </div>
              <div className={styles.subImgCont}>
                {" "}
                <img src={subImg} alt="" />
              </div>
              <p className={styles.text}>
                Your journey with us begins now. We're thrilled to have you as
                part of our vibrant family of voices. Together, let's make waves
                and create unforgettable moments. Thank you for choosing to be
                with us!
              </p>
              {/* Add any additional actions or content needed in the modal */}
            </div>
          </Modal>

          {/* {showModal && (
            <div className={styles.modal}>
              <div className={styles.modalContent}>
   
                <p className={styles.subPara}>You have joined successfully!</p>
              </div>
            </div>
          )} */}
        </div>
      </div>
      <FooterB />
    </>
  );
};

export default JoinWeAscend;
