import React from "react";

const BootcampIcon = () => {
  return (
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1_188)">
        <path
          d="M35.6832 6.13983C29.668 7.66912 27.6289 8.23553 27.4477 8.41678C27.1531 8.67733 27.0965 9.24373 27.3344 9.53826C27.4024 9.64022 28.4219 10.4105 29.6 11.2715C30.7668 12.1211 31.7184 12.8347 31.7184 12.8461C31.7184 12.8574 31.5145 13.8883 31.2652 15.123C31.016 16.3578 30.8121 17.6152 30.8121 17.9097C30.8121 20.3453 34.4598 23.5625 38.5719 24.7519C40.9281 25.4316 43.9188 25.5562 46.1164 25.0465C48.7559 24.4461 50.8176 23.1207 51.2594 21.75C51.316 21.5914 51.5652 20.4246 51.8258 19.1558L52.2902 16.8336L54.8844 16.1765C57.0367 15.6215 57.5352 15.4629 57.7504 15.225C58.0902 14.8512 58.0676 14.466 57.6938 14.0582C57.5125 13.8656 54.7824 11.8152 51.5992 9.50428C43.8395 3.82889 44.1906 4.07811 43.9074 4.08944C43.7828 4.10076 40.0785 5.01834 35.6832 6.13983ZM49.3449 10.1047C53.7289 13.3105 54.907 14.2281 54.7484 14.2961C54.6352 14.3414 51.5992 15.123 47.9969 16.0293L41.4606 17.6832L38.7645 15.7121C37.2918 14.6359 34.7656 12.7894 33.157 11.6113C31.2539 10.218 30.2797 9.44764 30.3816 9.391C30.4609 9.34569 33.1797 8.64334 36.4195 7.82772C39.6594 7.01209 42.5707 6.27576 42.8766 6.18514C43.1938 6.09451 43.5109 6.01522 43.5902 6.01522C43.6695 6.00389 46.2637 7.85037 49.3449 10.1047ZM37.0652 16.7203C39.059 18.1816 40.8035 19.439 40.9508 19.507C41.1434 19.5976 42.1516 19.3711 45.7086 18.4762C48.1895 17.8418 50.2399 17.3433 50.2625 17.3773C50.2852 17.4 50.1492 18.2722 49.9453 19.3144C49.6055 21.0816 49.5602 21.2515 49.209 21.6367C47.9289 23.0527 43.9074 23.9023 40.8715 23.4039C37.6996 22.8828 34.9129 21.3875 33.2816 19.3371C32.5566 18.4195 32.5453 18.2156 32.9645 15.984C33.1684 14.9191 33.3609 14.0469 33.3836 14.0469C33.4063 14.0469 35.0602 15.2476 37.0652 16.7203Z"
          fill="black"
        />
        <path
          d="M7.15899 17.8191C6.54727 18.0457 5.99219 18.5781 5.70899 19.2012L5.43711 19.7902L5.45977 34.143L5.49376 48.4844H3.02422H0.554693L0.282818 48.7676C-0.487494 49.5266 0.350787 52.0527 1.72149 53.1402C2.79766 53.9898 0.667975 53.9219 28.0934 53.9219C49.6055 53.9219 52.8227 53.8992 53.3211 53.7519C54.8391 53.2988 55.9945 51.7016 56.1531 49.8211C56.2098 49.1187 56.1984 49.0508 55.9266 48.7789L55.632 48.4844H53.1625H50.693L50.727 37.4394L50.7496 26.3832L50.4777 26.1113C50.1039 25.7375 49.5828 25.7375 49.2203 26.1113L48.9371 26.3832V37.1562V47.9293L48.6539 48.2012L48.382 48.4844H28.0934H7.80469L7.53282 48.2012L7.24961 47.9293V33.9504V19.9715L7.51016 19.7336L7.77071 19.4844H17.9887C25.2273 19.4844 28.2746 19.4504 28.4672 19.3598C29.0223 19.1105 29.1582 18.3855 28.7164 17.9551L28.4445 17.6719L17.9887 17.6832C10.2402 17.6832 7.44219 17.7285 7.15899 17.8191ZM54.2613 50.3535C54.2613 50.3875 54.1594 50.6027 54.0461 50.8406C53.7855 51.3504 53.2984 51.8262 52.868 51.9848C52.4148 52.1547 3.77188 52.1547 3.31876 51.9848C2.88829 51.8262 2.40118 51.3504 2.14063 50.8406C2.02735 50.6027 1.9254 50.3875 1.9254 50.3535C1.9254 50.3195 13.7066 50.2969 28.0934 50.2969C42.4914 50.2969 54.2613 50.3195 54.2613 50.3535Z"
          fill="black"
        />
        <path
          d="M21.9766 25.7148C21.75 25.9414 21.75 26.0887 21.75 34.4262V42.9223L22.0445 43.1488C22.2145 43.2848 22.475 43.3867 22.6223 43.3867C22.8941 43.3867 38.2324 35.423 38.6402 35.0719C38.9461 34.8227 39.0367 34.3355 38.8441 34.007C38.6176 33.5992 23.132 25.4883 22.5883 25.4883C22.3504 25.4883 22.1125 25.5789 21.9766 25.7148ZM31.073 31.7754C33.7918 33.2027 36.0234 34.4035 36.0234 34.4375C36.0234 34.4715 33.8711 35.6156 31.243 36.9863C28.6035 38.357 25.8055 39.8184 25.0125 40.2375L23.5625 40.9965V34.4262V27.8445L24.8426 28.5129C25.5336 28.8867 28.343 30.348 31.073 31.7754Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_188">
          <rect width="58" height="58" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BootcampIcon;
