import React from "react";

const PhoneIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3733 11.497H22.3733C22.3733 6.36705 18.5003 2.49805 13.3633 2.49805V4.49805C17.4253 4.49805 20.3733 7.44105 20.3733 11.497Z"
        fill="white"
      />
      <path
        d="M13.3753 8.49829C15.4783 8.49829 16.3753 9.39529 16.3753 11.4983H18.3753C18.3753 8.27329 16.6003 6.49829 13.3753 6.49829V8.49829ZM16.7973 13.9413C16.6051 13.7666 16.3526 13.6735 16.0931 13.6815C15.8335 13.6895 15.5873 13.7981 15.4063 13.9843L13.0133 16.4453C12.4373 16.3353 11.2793 15.9743 10.0873 14.7853C8.89528 13.5923 8.53428 12.4313 8.42728 11.8593L10.8863 9.46529C11.0727 9.28441 11.1814 9.03811 11.1894 8.77849C11.1975 8.51888 11.1042 8.26633 10.9293 8.07429L7.23428 4.01129C7.05932 3.81864 6.81616 3.70179 6.55643 3.68555C6.29671 3.6693 6.04088 3.75494 5.84328 3.92429L3.67328 5.78529C3.50039 5.9588 3.3972 6.18974 3.38328 6.43429C3.36828 6.68429 3.08228 12.6063 7.67428 17.2003C11.6803 21.2053 16.6983 21.4983 18.0803 21.4983C18.2823 21.4983 18.4063 21.4923 18.4393 21.4903C18.6838 21.4766 18.9146 21.3729 19.0873 21.1993L20.9473 19.0283C21.1167 18.8308 21.2026 18.5751 21.1865 18.3153C21.1704 18.0556 21.0538 17.8124 20.8613 17.6373L16.7973 13.9413Z"
        fill="white"
      />
    </svg>
  );
};

export default PhoneIcon;
