import React from "react";
import styles from "./WeAscendMobileComp.module.css";
import WeAscendLogoIcon from "../../assets/icons/WeAscendLogoIcon";
import bootcampMainImage from "../../assets/images/bootcampMainImage.png";
import GrowthIcon from "../../assets/icons/GrowthIcon";
import amalMainImage from "../../assets/images/amalMainImage.png";
import womenInCirclesPlus from "../../assets/images/womenInCirclesPlus.png";
import weAscendLogoVertImg from "../../assets/images/weAscendLogoVertImg.png";

import victory from "../../assets/images/victory.jpg";
import lock from "../../assets/images/lock.png";
import creditProfile from "../../assets/images/creditProfile.png";

import OvalIcon from "../../assets/icons/OvalIcon";

import VictoryCircularText from "../../assets/icons/VictoryCircularText";
import WeAscendLogoVertIcon from "../../assets/icons/WeAscendLogoVertIcon";
import { useNavigate } from "react-router-dom";
import ArrowIconWhite from "../../assets/icons/ArrowIconWhite";

const WeAscendMobileComp = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.weAscendCont}>
      <div className={`${styles.percentWsmesCont1} ${styles.cardPink}`}>
        <div className={styles.percentCont}>
          <span className={styles.percentText}>17%</span>
          <span className={styles.percentText2}>
            Aspiring women entrepreneurs
          </span>
        </div>
        <div className={styles.percentCont}>
          <span className={styles.percentText}>60%</span>
          <span className={styles.percentText2}>Stalled by self-doubt</span>
        </div>
        <div className={styles.percentCont}>
          <span className={styles.percentText}>74%</span>
          <span className={styles.percentText2}>
            WSMEs finance knowledge barrier
          </span>
        </div>
        <div className={styles.percentCont}>
          <span className={styles.percentText}>2.3%</span>
          <span className={styles.percentText2}>
            VC funding for women founders
          </span>
        </div>
      </div>

      {/* --------------------new card--------------------------------- */}

      <div className={`${styles.percentWsmesCont2} ${styles.cardWhite}`}>
        <span className={styles.trillion}>$1.4 Tn</span>
        <span className={styles.estimated}>Estimated annual credit gap</span>
      </div>

      {/* ---------------------logo cont----------------------- */}

      <div className={`${styles.logoCont} ${styles.cardWhite}`}>
        <div className={styles.ascendLogoWrap}>
          {" "}
          {/* <WeAscendLogoVertIcon /> */}
          <img src={weAscendLogoVertImg} alt="" />
        </div>
      </div>

      {/* -------------------------------------------- */}

      <div className={`${styles.bootcampCont} ${styles.cardWhite}`}>
        <span className={styles.title}>WeAscend Bootcamp</span>
        <span className={styles.headline}>
          Master Business and Finance 4x Faster
        </span>
        <span className={styles.desc}>
          AI simplifies complex concepts, tailors them to your business
        </span>
        {/* <div className={styles.icon4xCont}>
                    <Icon4x />
                  </div> */}

        <img
          src={bootcampMainImage}
          alt=""
          className={styles.bootcampMainImage}
        />

        <div className={styles.learnMore}>
          <button
            className={styles.learnMorePink}
            onClick={() => {
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0; // For Chrome,
              navigate("/products/bootcamp");
            }}
          >
            Learn More
          </button>
        </div>
      </div>

      {/* ---------------------------------------------------------------------- */}

      <div className={`${styles.compass} ${styles.cardPink}`}>
        <span className={styles.titleWhite}>WeAscend Inner Compass</span>
        <span className={styles.headlineWhite}>Unleash Your Confidence</span>
        <span className={styles.descWhite}>
          “Empower your self-awareness with personality assessments, unlock your
          leadership potential.
        </span>

        <div className={`${styles.learnMoreCont} `}>
          <div className={styles.learnMore}>
            <button
              className={styles.learnMoreWhite}
              onClick={() => {
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                // Assuming 'navigate' is a function to navigate to a different page
                navigate("/products/inner-compass");
              }}
            >
              Learn More
            </button>
          </div>
          <div className={styles.growthIcon}>
            <GrowthIcon />
          </div>
        </div>
      </div>

      {/* ---------------------------------------------------------------------------------------- */}

      <div className={`${styles.amal} ${styles.cardPink}`}>
        <span className={styles.titleWhite}>WeAscend AMAL Bot</span>
        <span className={styles.headlineWhite}>Your AI Biz Bot Bestie</span>
        <span className={styles.descWhite}>
          Advice, pep talks, venting, emotional support - 24/7
        </span>
        <div className={styles.amalMainImageCont}>
          <img src={amalMainImage} alt="" className={styles.amalMainImage} />
        </div>

        <div className={styles.learnMore}>
          <button
            className={styles.learnMoreWhite}
            onClick={() => {
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
              // Assuming 'navigate' is a function to navigate to a different page
              navigate("/products/amal-bot");
            }}
          >
            Learn More
          </button>
        </div>
      </div>

      {/* ------------------------------------------------------------------------------------------ */}

      <div className={`${styles.circle} ${styles.cardWhite}`}>
        <span className={styles.title}>WeAscend Circle</span>
        <span className={styles.headline}>Connect, Celebrate, Succeed</span>
        <span className={styles.desc}>
          Your tribe of inspiring female entrepreneurs awaits
        </span>
        <div className={styles.learnMoreCont}>
          <div className={styles.learnMore}>
            <button
              className={styles.learnMorePink}
              onClick={() => {
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                // Assuming 'navigate' is a function to navigate to a different page
                navigate("/products/circle");
              }}
            >
              Learn More
            </button>
            <img
              src={womenInCirclesPlus}
              alt=""
              className={styles.womenInCirclesPlus}
            />
          </div>
        </div>
      </div>

      {/* ---------------------------------------------------------------------------------------- */}

      <div className={`${styles.credit} ${styles.cardWhite}`}>
        <span className={styles.title}>WeAscend Credit</span>
        <span className={styles.headline}>Fast Track to Business Funding</span>
        <span className={styles.desc}>
          Demystify loans, secure financing 2x faster with secure data sharing
        </span>

        <div className={styles.creditedByCont}>
          <span>Your Account is credited by Rs 500,000</span>
        </div>

        <div className={styles.lockProfileCont}>
          <img src={lock} alt="" className={styles.lockImg} />

          <div className={styles.ovalIconCont}>
            <OvalIcon />
          </div>

          <img src={creditProfile} alt="" className={styles.profilePicImg} />
        </div>

        <div className={styles.learnMore}>
          <button
            className={styles.learnMorePink}
            onClick={() => {
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
              // Assuming 'navigate' is a function to navigate to a different page
              navigate("/products/credit");
            }}
          >
            Learn More
          </button>
        </div>
      </div>

      {/* ------------------------------------------------------------ */}

      <div className={`${styles.victoryImgCont}`}>
        <img
          src={victory}
          alt=""
          className={`${styles.victoryImg} ${styles.cardVictory}`}
        />
        <div className={`${styles.victoryCircleText}`}>
          <VictoryCircularText />
        </div>
      </div>
    </div>
  );
};

export default WeAscendMobileComp;
