import React from "react";
import styles from "../bootcamp/Bootcamp.module.css";
import Navbar from "../../components/navbar/Navbar";
import ic1img from "../../assets/images/ic1img.png";
import ic2img from "../../assets/images/ic2img.png";
import ic3img from "../../assets/images/ic3img.png";
import weAscendLogoW from "../../assets/images/weAscendLogoW.png";
import backArrow from "../../assets/images/backArrow.png";
import courseComp from "../../assets/images/courseComp.png";
import innerCompassImg from "../../assets/images/innerCompassImg.png";
import amalBotImg from "../../assets/images/amalBotImg.png";
import circleImg from "../../assets/images/circleImg.png";
import Footer from "../../components/footer/Footer";
import JoinBtn from "../../components/joinBtn/JoinBtn";

const InnerCompass = ({ isScrolledToDiv }) => {
  return (
    <>
      <Navbar isScrolledToDiv={isScrolledToDiv} />
      <div className={styles.mainCont}>
        <div className={styles.sec1}>
          <div className={styles.sec1a}>
            <span className={styles.aspiring}>Inner Compass</span>
            <span className={styles.headline}>
              Navigate your journey with confidenceand clarity
            </span>

            <div className={styles.ascendBtnWrap}>
              <div className={styles.ascendBtnWrap}>
                <JoinBtn />
              </div>
            </div>
          </div>
          <div className={styles.sec1b}>
            <img src={ic1img} alt="" style={{ width: "100%" }} />
          </div>
        </div>

        {/* -------------------------------------------------------------- */}

        <div className={styles.unlockParaCont}>
          <p className={styles.unlockParaText}>
            Empower your leadership with Inner Compass, a program designed to
            help women navigate the entrepreneurial landscape with confidence,
            clarity, and a strong sense of purpose.
          </p>
        </div>

        {/* ------------------------------sec2------------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec2a}>
            <span className={styles.title}>Benefits</span>
            <span className={styles.headline}>
              <strong>Enhance self-awareness:</strong> Gain a deeper
              understanding of your strengths, weaknesses, and leadership style.
            </span>
            <span className={styles.headline}>
              <strong>Develop impactful leadership:</strong>  Hone your
              communication, decision-making, and team management skills.
            </span>
            <span className={styles.headline}>
              <strong>Build resilience:</strong> Master stress management
              techniques and cultivate work-life balance for a sustainable
              career.
            </span>
          </div>

          <div className={styles.sec2b}>
            <img src={ic2img} alt="" style={{ width: "100%" }} />
          </div>
        </div>

        {/* -----------------------------sec3-------------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec3b}>
            <img src={ic3img} alt="" style={{ width: "100%" }} />
          </div>
          <div className={styles.sec2a}>
            <span className={styles.title}>Features</span>
            <span className={styles.headline}>
              <strong>Psychometric assessments:</strong> AI simplifies complex
              concepts, tailoring them to your industry for efficient learning
            </span>
            <span className={styles.headline}>
              <strong>Interactive Learning:</strong> Gain the business & finance
              knowledge you need to launch, scale, and succeed.
            </span>
            <span className={styles.headline}>
              <strong>Customized learning paths:</strong> Equip yourself with
              the tools and strategies to achieve your goals faster.
            </span>
          </div>
        </div>

        {/* ----------------------------------------------------------------------------------- */}

        <div className={styles.howItWorksCont}>
          <span className={styles.worksText}>How It Works</span>
          <div className={styles.howItWorksCardsCont}>
            <div className={styles.howItWorksCard}>
              <div>
                {" "}
                <span className={styles.orangeText}>1</span>
              </div>
              <span className={styles.worksTitle}>Initial Assessment</span>
              <span className={styles.worksDesc}>
                Begin with psychometric tests to identify strengths and growth
                areas.
              </span>
            </div>
            <div className={styles.howItWorksCard}>
              <div>
                {" "}
                <span className={styles.orangeText}>2</span>
              </div>
              <span className={styles.worksTitle}>Personalized Learning</span>
              <span className={styles.worksDesc}>
                Follow a tailored path aligned with your entrepreneurial phase
                and insights.
              </span>
            </div>
            <div className={styles.howItWorksCard}>
              <div>
                {" "}
                <span className={styles.orangeText}>3</span>
              </div>
              <span className={styles.worksTitle}>Skill Application</span>
              <span className={styles.worksDesc}>
                Use new skills to boost leadership, decision-making, and
                emotional intelligence.
              </span>
            </div>
            <div className={styles.howItWorksCard}>
              <div>
                {" "}
                <span className={styles.orangeText}>4</span>
              </div>
              <span className={styles.worksTitle}>Continuous Growth</span>
              <span className={styles.worksDesc}>
                 Leverage resources for sustained development as your venture
                progresses.
              </span>
            </div>
          </div>
        </div>

        <div className={styles.redyToCont}>
          <div className={styles.weAscendLogoWcont}>
            {" "}
            <img src={weAscendLogoW} alt="" />
          </div>
          <p className={styles.readyToTurn}>
            WeAscend Inner Compass acknowledges the hurdles and aspirations
            unique to female entrepreneurs, offering a platform for growth,
            innovation, and success. Begin your ascent today—transform your
            challenges into opportunities and acquire the skills you need to
            write your success story.
          </p>
          <JoinBtn />
        </div>

        {/* -----------------------------footer--------------------------- */}
      </div>
      <Footer />
    </>
  );
};

export default InnerCompass;
