import React from "react";
import styles from "./AspiringEntrepreneurs.module.css";
import Navbar from "../../components/navbar/Navbar";
import sec1img from "../../assets/images/sec1img.png";
import backArrow from "../../assets/images/backArrow.png";
import courseComp from "../../assets/images/courseComp.png";
import innerCompassImg from "../../assets/images/innerCompassImg.png";
import amalBotImg from "../../assets/images/amalBotImg.png";
import circleImg from "../../assets/images/circleImg.png";
import Footer from "../../components/footer/Footer";
import JoinBtn from "../../components/joinBtn/JoinBtn";

const AspiringEntrepreneurs = ({ isScrolledToDiv }) => {
  return (
    <>
      <Navbar isScrolledToDiv={isScrolledToDiv} />
      <div className={styles.mainCont}>
        <div className={styles.sec1}>
          <div className={styles.sec1a}>
            <span className={styles.aspiring}>Aspiring Entrepreneurs</span>
            <span className={styles.headline}>
              Begin Your Journey with WeAscend
            </span>
            <span className={styles.desc}>
              A sanctuary that nurtures female innovators' aspirations into
              success, equipping you with tools and community to transform your
              vision into reality
            </span>
            <div className={styles.ascendBtnWrap}>
              <JoinBtn />
            </div>
          </div>
          <div className={styles.sec1b}>
            <img src={sec1img} alt="" style={{ width: "100%" }} />
          </div>
        </div>

        {/* ------------------------------sec2------------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec2b}>
            <img src={courseComp} alt="" style={{ width: "100%" }} />
          </div>
          <div className={styles.sec2a}>
            <span className={styles.title}>WeAscend Bootcamp</span>
            <span className={styles.headline}>From Idea to Launchpad</span>
            <span className={styles.desc}>
              Equips Aspiring Entrepreneurs with the skills to validate ideas,
              craft business models using lean canvas, & master financial
              fundamentals, setting a solid foundation for business success.
            </span>
          </div>
        </div>

        {/* -----------------------------sec3-------------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec2a}>
            <span className={styles.title}>WeAscend Inner Compass</span>
            <span className={styles.headline}>Break Free from Self-doubt</span>
            <span className={styles.desc}>
              Designed to enhance your self-awareness, resilience, and strategic
              goal-setting. This program cultivates the leadership qualities and
              emotional intelligence essential for turning ideas into reality.
            </span>
          </div>
          <div className={styles.sec3b}>
            <img src={innerCompassImg} alt="" style={{ width: "100%" }} />
          </div>
        </div>

        {/* --------------------------------sec4-------------------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec2b}>
            <img src={amalBotImg} alt="" style={{ width: "100%" }} />
          </div>
          <div className={styles.sec2a}>
            <span className={styles.title}>WeAscend AMAL Bot</span>
            <span className={styles.headline}>
              Your Sidekick on the Startup Adventure
            </span>
            <span className={styles.desc}>
              From instant access to business essentials to personalized
              coaching that understands your unique challenges, AMAL is your
              24/7 support encouraging you every step of the way.
            </span>
          </div>
        </div>

        {/* --------------------------------------sec5----------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec2a}>
            <span className={styles.title}>WeAscend Circle</span>
            <span className={styles.headline}>
              Incubate Your Dream with Fellow Trailblazers
            </span>
            <span className={styles.desc}>
              Dive into a world of shared experiences, challenges, and triumphs,
              fostering a supportive network that propels your venture forward.
              It's your ultimate launchpad for inspiration & learning.
            </span>
          </div>
          <div className={styles.sec3b}>
            <img src={circleImg} alt="" style={{ width: "100%" }} />
          </div>
        </div>
        <div className={styles.redyToCont}>
          <p className={styles.readyToTurn}>
            Ready to turn your dream into reality? WeAscend offers the tools,
            support, and community you need to succeed. Join WeAscend and
            takethe first step towards the extraordinary!
          </p>
          <JoinBtn />
        </div>

        {/* -----------------------------footer--------------------------- */}
      </div>
      <Footer />
    </>
  );
};

export default AspiringEntrepreneurs;
