import React from "react";
import styles from "./Bootcamp.module.css";
import Navbar from "../../components/navbar/Navbar";
import bc1img from "../../assets/images/bc1img.png";
import bc2img from "../../assets/images/bc2img.png";
import bc3img from "../../assets/images/bc3img.png";
import weAscendLogoW from "../../assets/images/weAscendLogoW.png";
import backArrow from "../../assets/images/backArrow.png";
import courseComp from "../../assets/images/courseComp.png";
import innerCompassImg from "../../assets/images/innerCompassImg.png";
import amalBotImg from "../../assets/images/amalBotImg.png";
import circleImg from "../../assets/images/circleImg.png";
import Footer from "../../components/footer/Footer";
import JoinBtn from "../../components/joinBtn/JoinBtn";

const Bootcamp = ({ isScrolledToDiv }) => {
  return (
    <>
      <Navbar isScrolledToDiv={isScrolledToDiv} />
      <div className={styles.mainCont}>
        <div className={styles.sec1}>
          <div className={styles.sec1a}>
            <span className={styles.aspiring}>Bootcamp</span>
            <span className={styles.headline}>
              Upskill Your Business Journey, Unleash Your Potential
            </span>

            <div className={styles.ascendBtnWrap}>
              <div className={styles.ascendBtnWrap}>
                <JoinBtn />
              </div>
            </div>
          </div>
          <div className={styles.sec1b}>
            <img src={bc1img} alt="" style={{ width: "100%" }} />
          </div>
        </div>

        {/* -------------------------------------------------------------- */}

        <div className={styles.unlockParaCont}>
          <p className={styles.unlockParaText}>
            Unlock your business's potential with our Bootcamp, where{" "}
            <span className={styles.grayBold}> innovative learning </span> meets{" "}
            <span className={styles.grayBold}>practical application,</span>{" "}
            setting the{" "}
            <span className={styles.grayBold}>
              foundation for your entrepreneurial success.
            </span>{" "}
            With Bootcamp,you can grow your{" "}
            <span className={styles.grayBold}>
              business and financial knowledge
            </span>{" "}
            from anywhere, at anytime.
          </p>
        </div>

        {/* ------------------------------sec2------------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec2a}>
            <span className={styles.title}>Benefits</span>
            <span className={styles.headline}>
              <strong>Level up 4x faster:</strong> AI simplifies complex
              concepts, tailoring them to your industry for efficient learning
            </span>
            <span className={styles.headline}>
              <strong>Master key skills: :</strong> Gain the business & finance
              knowledge you need to launch, scale, and succeed.
            </span>
            <span className={styles.headline}>
              <strong>Fast-track growth:</strong> Equip yourself with the tools
              and strategies to achieve your goals faster.
            </span>
          </div>

          <div className={styles.sec2b}>
            <img src={bc2img} alt="" style={{ width: "100%" }} />
          </div>
        </div>

        {/* -----------------------------sec3-------------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec3b}>
            <img src={bc3img} alt="" style={{ width: "100%" }} />
          </div>
          <div className={styles.sec2a}>
            <span className={styles.title}>Features</span>
            <span className={styles.headline}>
              <strong>Flexible Learning:</strong> Access courses anytime,
              anywhere, fitting your schedule.
            </span>
            <span className={styles.headline}>
              <strong>Interactive Experience:</strong> Engage with dynamic
              content that makes learning active.
            </span>
            <span className={styles.headline}>
              <strong>Personalized Pathways:</strong> Courses tailored to your
              industry,stage, and challenges.
            </span>
          </div>
        </div>

        {/* ----------------------------------------------------------------------------------- */}

        <div className={styles.howItWorksCont}>
          <span className={styles.worksText}>How It Works</span>
          <div className={styles.howItWorksCardsCont}>
            <div className={styles.howItWorksCard}>
              <div>
                {" "}
                <span className={styles.orangeText}>1</span>
              </div>
              <span className={styles.worksTitle}>Identify Your Needs</span>
              <span className={styles.worksDesc}>
                Outline your stage,background, and aspirations.
              </span>
            </div>
            <div className={styles.howItWorksCard}>
              <div>
                {" "}
                <span className={styles.orangeText}>2</span>
              </div>
              <span className={styles.worksTitle}>Tailored Learning</span>
              <span className={styles.worksDesc}>
                Receive course recommendations that match your unique
                entrepreneurial journey.
              </span>
            </div>
            <div className={styles.howItWorksCard}>
              <div>
                {" "}
                <span className={styles.orangeText}>3</span>
              </div>
              <span className={styles.worksTitle}>Engage and Grow</span>
              <span className={styles.worksDesc}>
                Dive into interactive, AI-powered content designed for
                real-world applications.
              </span>
            </div>
            <div className={styles.howItWorksCard}>
              <div>
                {" "}
                <span className={styles.orangeText}>4</span>
              </div>
              <span className={styles.worksTitle}>Apply and Ascend</span>
              <span className={styles.worksDesc}>
                Utilize new knowledge to overcome obstacles and seize
                opportunities.
              </span>
            </div>
          </div>
        </div>

        <div className={styles.redyToCont}>
          <div className={styles.weAscendLogoWcont}>
            {" "}
            <img src={weAscendLogoW} alt="" />
          </div>
          <p className={styles.readyToTurn}>
            WeAscend Bootcamp acknowledges the hurdles and aspirations unique to
            female entrepreneurs, offering a platform for growth, innovation,
            and success. Begin your ascent today—transform your challenges into
            opportunities and acquire the skills you need to write your success
            story.
          </p>
          <JoinBtn />
        </div>

        {/* -----------------------------footer--------------------------- */}
      </div>
      <Footer />
    </>
  );
};

export default Bootcamp;
