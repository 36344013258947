import React from "react";
import styles from "../bootcamp/Bootcamp.module.css";
import Navbar from "../../components/navbar/Navbar";
import cre1img from "../../assets/images/cre1img.png";
import cre2img from "../../assets/images/cre2img.png";
import cre3img from "../../assets/images/cre3img.png";
import weAscendLogoW from "../../assets/images/weAscendLogoW.png";
import backArrow from "../../assets/images/backArrow.png";
import courseComp from "../../assets/images/courseComp.png";
import innerCompassImg from "../../assets/images/innerCompassImg.png";
import amalBotImg from "../../assets/images/amalBotImg.png";
import circleImg from "../../assets/images/circleImg.png";
import Footer from "../../components/footer/Footer";
import JoinBtn from "../../components/joinBtn/JoinBtn";

const Credit = ({ isScrolledToDiv }) => {
  return (
    <>
      <Navbar isScrolledToDiv={isScrolledToDiv} />
      <div className={styles.mainCont}>
        <div className={styles.sec1}>
          <div className={styles.sec1a}>
            <span className={styles.aspiring}>Credit</span>
            <span className={styles.headline}>
              Speak Finance Fluently, Secure Funding Faster.
            </span>

            <div className={styles.ascendBtnWrap}>
              <div className={styles.ascendBtnWrap}>
                <JoinBtn />
              </div>
            </div>
          </div>
          <div className={styles.sec1b}>
            <img src={cre1img} alt="" style={{ width: "100%" }} />
          </div>
        </div>

        {/* -------------------------------------------------------------- */}

        <div className={styles.unlockParaCont}>
          <p className={styles.unlockParaText}>
            Access capital more easily with WeAscend Credit, providing strategic
            financial solutions designed with women entrepreneurs in mind.
            Understand the financial landscape, assess your creditworthiness,
            and find the best financing options for your business.
          </p>
        </div>

        {/* ------------------------------sec2------------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec2a}>
            <span className={styles.title}>Benefits</span>
            <span className={styles.headline}>
              <strong>Demystify finance:</strong> Gain the knowledge and
              confidence to navigate funding options.
            </span>
            <span className={styles.headline}>
              <strong>Secure funding faster:</strong> Get an alternative data
              based Credit Risk Assessment Score for lenders.
            </span>
            <span className={styles.headline}>
              <strong>Find the perfect fit:</strong> Identify financing
              solutions tailored to your business needs.
            </span>
          </div>

          <div className={styles.sec2b}>
            <img src={cre2img} alt="" style={{ width: "100%" }} />
          </div>
        </div>

        {/* -----------------------------sec3-------------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec3b}>
            <img src={cre3img} alt="" style={{ width: "100%" }} />
          </div>
          <div className={styles.sec2a}>
            <span className={styles.title}>Features</span>
            <span className={styles.headline}>
              <strong>Financial literacy modules:</strong> Learn about loans,
              grants, investments, and more.
            </span>
            <span className={styles.headline}>
              <strong>Alternative data-based credit score:</strong> Receive a
              score you can securely share with lenders.
            </span>
            <span className={styles.headline}>
              <strong>Financing product comparison tool:</strong> Compare
              options based on your business needs.
            </span>
          </div>
        </div>

        {/* ----------------------------------------------------------------------------------- */}

        <div className={styles.howItWorksCont}>
          <span className={styles.worksText}>How It Works</span>
          <div className={styles.howItWorksCardsCont}>
            <div className={styles.howItWorksCard}>
              <div>
                {" "}
                <span className={styles.orangeText}>1</span>
              </div>
              <span className={styles.worksTitle}>Learn</span>
              <span className={styles.worksDesc}>
                Gain financial literacy through interactive modules.
              </span>
            </div>
            <div className={styles.howItWorksCard}>
              <div>
                {" "}
                <span className={styles.orangeText}>2</span>
              </div>
              <span className={styles.worksTitle}>Assess</span>
              <span className={styles.worksDesc}>
                Get your alternative data based credit score.
              </span>
            </div>
            <div className={styles.howItWorksCard}>
              <div>
                {" "}
                <span className={styles.orangeText}>3</span>
              </div>
              <span className={styles.worksTitle}>Compare</span>
              <span className={styles.worksDesc}>
                Find the ideal financing option using our comparison tool.
              </span>
            </div>
          </div>
        </div>

        <div className={styles.redyToCont}>
          <div className={styles.weAscendLogoWcont}>
            {" "}
            <img src={weAscendLogoW} alt="" />
          </div>
          <p className={styles.readyToTurn}>
            Join our platform today to unlock a wealth of resources designed to
            demystify the financial process and accelerate your journey to
            securing funding. Step into financial independence and business
            growth with WeAscend Credit.
          </p>
          <JoinBtn />
        </div>

        {/* -----------------------------footer--------------------------- */}
      </div>
      <Footer />
    </>
  );
};

export default Credit;
