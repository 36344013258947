import React from "react";
import styles from "../bootcamp/Bootcamp.module.css";
import Navbar from "../../components/navbar/Navbar";
import ab1 from "../../assets/images/ab1.png";
import ab2 from "../../assets/images/ab2.png";
import ab3 from "../../assets/images/ab3.png";
import weAscendLogoW from "../../assets/images/weAscendLogoW.png";
import backArrow from "../../assets/images/backArrow.png";
import courseComp from "../../assets/images/courseComp.png";
import innerCompassImg from "../../assets/images/innerCompassImg.png";
import amalBotImg from "../../assets/images/amalBotImg.png";
import circleImg from "../../assets/images/circleImg.png";
import Footer from "../../components/footer/Footer";
import JoinBtn from "../../components/joinBtn/JoinBtn";

const AmalBot = ({ isScrolledToDiv }) => {
  return (
    <>
      <Navbar isScrolledToDiv={isScrolledToDiv} />
      <div className={styles.mainCont}>
        <div className={styles.sec1}>
          <div className={styles.sec1a}>
            <span className={styles.aspiring}>AMAL Bot</span>
            <span className={styles.headline}>
              Your Bestie Biz Bot: Empowering Every Step of Your Journey
            </span>

            <div className={styles.ascendBtnWrap}>
              <div className={styles.ascendBtnWrap}>
                <JoinBtn />
              </div>
            </div>
          </div>
          <div className={styles.sec1b}>
            <img src={ab1} alt="" style={{ width: "100%" }} />
          </div>
        </div>

        {/* -------------------------------------------------------------- */}

        <div className={styles.unlockParaCont}>
          <p className={styles.unlockParaText}>
            Meet AMAL, your dedicated AI advisor, ready to provide instant,
            tailored business and emotional support, ensuring you're never alone
            on your journey.
          </p>
        </div>

        {/* ------------------------------sec2------------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec2a}>
            <span className={styles.title}>Benefits</span>
            <span className={styles.headline}>
              <strong>24/7 support:</strong> Always available for business
              advice, pep talks, emotional venting, and more.
            </span>
            <span className={styles.headline}>
              <strong>Personalized guidance:</strong> Tailored advice and
              support based on your stage and business needs.
            </span>
            <span className={styles.headline}>
              <strong>Emotional well-being:</strong> A safe space to share
              challenges and find understanding, reducing stress and boosting
              confidence.
            </span>
          </div>

          <div className={styles.sec2b}>
            <img src={ab2} alt="" style={{ width: "100%" }} />
          </div>
        </div>

        {/* -----------------------------sec3-------------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec3b}>
            <img src={ab3} alt="" style={{ width: "100%" }} />
          </div>
          <div className={styles.sec2a}>
            <span className={styles.title}>Features</span>
            <span className={styles.headline}>
              <strong>Instant access to knowledge:</strong> AMAL Bot provides
              on-demand access to a vast array of startup and business insights.
            </span>
            <span className={styles.headline}>
              <strong>Personalized advice:</strong> Chat with your AI coach who
              understands your unique challenges and goals.
            </span>
            <span className={styles.headline}>
              <strong>Evolves with you:</strong>  Adapts as your business grows,
              offering relevant support at every step.
            </span>
          </div>
        </div>

        {/* ----------------------------------------------------------------------------------- */}

        <div className={styles.howItWorksCont}>
          <span className={styles.worksText}>How It Works</span>
          <div className={styles.howItWorksCardsCont}>
            <div className={styles.howItWorksCard}>
              <div>
                {" "}
                <span className={styles.orangeText}>1</span>
              </div>
              <span className={styles.worksTitle}>Create Your Profile</span>
              <span className={styles.worksDesc}>
                Share your stage and business details for personalized guidance.
              </span>
            </div>
            <div className={styles.howItWorksCard}>
              <div>
                {" "}
                <span className={styles.orangeText}>2</span>
              </div>
              <span className={styles.worksTitle}>Ask Your Question</span>
              <span className={styles.worksDesc}>
                Get instant answers to specific business queries.
              </span>
            </div>
            <div className={styles.howItWorksCard}>
              <div>
                {" "}
                <span className={styles.orangeText}>3</span>
              </div>
              <span className={styles.worksTitle}>Chat With AMAL</span>
              <span className={styles.worksDesc}>
                Have in-depth conversations about your challenges, goals, and
                emotions.
              </span>
            </div>
          </div>
        </div>

        <div className={styles.redyToCont}>
          <div className={styles.weAscendLogoWcont}>
            {" "}
            <img src={weAscendLogoW} alt="" />
          </div>
          <p className={styles.readyToTurn}>
            Ready to unlock your full potential with anAI companion who always
            has your back? Join WeAscend today and experience thepower of AMAL
            Bot!
          </p>
          <JoinBtn />
        </div>

        {/* -----------------------------footer--------------------------- */}
      </div>
      <Footer />
    </>
  );
};

export default AmalBot;
