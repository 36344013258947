import React from "react";

const EyeCloseIcon = () => {
  return (
    <svg
      width="21"
      height="13"
      viewBox="0 0 21 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0101 6.12012C19.8301 5.87012 15.56 0.110107 10.1 0.110107C4.64003 0.110107 0.37 5.87012 0.19 6.12012C0.02 6.35012 0.02 6.6699 0.19 6.8999C0.37 7.1499 4.64003 12.8999 10.1 12.8999C15.56 12.8999 19.8301 7.1499 20.0101 6.8999C20.1801 6.6699 20.1801 6.35012 20.0101 6.12012ZM10.1 11.5801C6.08003 11.5801 2.6 7.76001 1.57 6.51001C2.6 5.26001 6.07003 1.43994 10.1 1.43994C14.12 1.43994 17.5999 5.26001 18.6299 6.51001C17.5999 7.76001 14.13 11.5801 10.1 11.5801Z"
        fill="#6D6D6D"
      />
      <path
        d="M10.0999 3.02002C8.17985 3.02002 6.60986 4.59001 6.60986 6.51001C6.60986 8.43001 8.17985 10 10.0999 10C12.0199 10 13.59 8.43001 13.59 6.51001C13.59 4.59001 12.0199 3.02002 10.0999 3.02002ZM10.0999 8.84009C8.81985 8.84009 7.7699 7.79001 7.7699 6.51001C7.7699 5.23001 8.81985 4.17993 10.0999 4.17993C11.3799 4.17993 12.4298 5.23001 12.4298 6.51001C12.4298 7.79001 11.3799 8.84009 10.0999 8.84009Z"
        fill="#6D6D6D"
      />
      <path
        d="M20.0008 11.1838L1.1776 0.316223C0.875107 0.14158 0.488363 0.24512 0.313721 0.547609C0.139079 0.850098 0.242661 1.23701 0.545151 1.41165L19.3683 12.2792C19.6708 12.4538 20.0576 12.35 20.2322 12.0475C20.4068 11.7451 20.3032 11.3584 20.0008 11.1838Z"
        fill="#6D6D6D"
      />
    </svg>
  );
};

export default EyeCloseIcon;
