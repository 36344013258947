import React from "react";
import styles from "../aspiringEntrepreneurs/AspiringEntrepreneurs.module.css";
import Navbar from "../../components/navbar/Navbar";
import backArrow from "../../assets/images/backArrow.png";

import elMainImg from "../../assets/images/elMainImg.png";
import el2img from "../../assets/images/el2img.png";
import el3img from "../../assets/images/el3img.png";
import el4img from "../../assets/images/el4img.png";
import el5img from "../../assets/images/el5img.png";
import el6img from "../../assets/images/el6img.png";
import el7img from "../../assets/images/el7img.png";

import Footer from "../../components/footer/Footer";
import JoinBtn from "../../components/joinBtn/JoinBtn";

const EmergingLeaders = () => {
  return (
    <>
      <Navbar />
      <div className={styles.mainCont}>
        <div className={styles.sec1}>
          <div className={styles.sec1a}>
            <span className={styles.aspiring}>Emerging Leaders</span>
            <span className={styles.headline}>
              Build Your Legacy with WeAscend
            </span>
            <span className={styles.desc}>
              A tailored platform empowering Emerging Business Leaders to master
              strategic innovation and join forces with industry leaders
            </span>
            <div className={styles.ascendBtnWrap}>
              <JoinBtn />
            </div>
          </div>
          <div className={styles.sec1b}>
            <img src={elMainImg} alt="" style={{ width: "100%" }} />
          </div>
        </div>

        {/* ------------------------------sec2------------------------- */}

        {/* -----------------------------sec3-------------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec3b}>
            <img src={el2img} alt="" style={{ width: "100%" }} />
          </div>
          <div className={styles.sec2a}>
            <span className={styles.title}>WeAscend Innovation Lab</span>
            <span className={styles.headline}>Lead, Innovate, Globalize</span>
            <span className={styles.desc}>
              Ignite transformative ideas and redefine industry standards.
              Harness expert-driven strategies in innovation management, conquer
              global markets, and navigate the complexities of corporate
              finance.
            </span>
          </div>
        </div>

        {/* --------------------------------sec4-------------------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec2a}>
            <span className={styles.title}>WeAscend Inner Compass</span>
            <span className={styles.headline}>
              Sharpen Your Edge, Stay Ahead of the Curve
            </span>
            <span className={styles.desc}>
              Master strategic decision-making and cultivate a legacy of
              mentorship. Forge ahead with confidence, sculpt the leaders of
              tomorrow, and navigate the evolving business arena with precision
              and insight.
            </span>
          </div>
          <div className={styles.sec2b}>
            <img src={el3img} alt="" style={{ width: "100%" }} />
          </div>
        </div>

        {/* --------------------------------------sec5----------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec3b}>
            <img src={el5img} alt="" style={{ width: "100%" }} />
          </div>
          <div className={styles.sec2a}>
            <span className={styles.title}>WeAscend Circle</span>
            <span className={styles.headline}>
              Nurture Today's Innovators into Future Leaders
            </span>
            <span className={styles.desc}>
              Empowers you to sculpt the entrepreneurial landscape by mentoring
              aspiring innovators, spearheading strategic alliances, and diving
              into rich dialogues for unparalleled growth.
            </span>
          </div>
        </div>

        {/* --------------------------------------sec6-------------------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec2a}>
            <span className={styles.title}>WeAscend AMAL Bot</span>
            <span className={styles.headline}>
              Cultivate Clarity and Resilience with AMAL
            </span>
            <span className={styles.desc}>
              A confidential sphere for strategic brainstorming, serves as an
              innovative catalyst for fresh insights, and fortifies your
              leadership journey with tailored stress management techniques.
            </span>
          </div>
          <div className={styles.sec2b}>
            <img src={el6img} alt="" style={{ width: "100%" }} />
          </div>
        </div>

        <div className={styles.sec2}>
          <div className={styles.sec2b}>
            <img src={el7img} alt="" style={{ width: "100%" }} />
          </div>

          <div className={styles.sec2a}>
            <span className={styles.title}>WeAscend Credit</span>
            <span className={styles.headline}>
              Strategic Finance for Global Impact
            </span>
            <span className={styles.desc}>
              Equips you with expert financial strategies for IPOs, mergers, and
              global expansion, plus access to innovation funding and robust
              financial health tactics, driving your venture to peak success.
            </span>
          </div>
        </div>

        <div className={styles.redyToCont}>
          <p className={styles.readyToTurn}>
            Ready to turn your dream into reality? WeAscend offers the tools,
            support, and community you need to succeed. Join WeAscend and
            takethe first step towards the extraordinary!
          </p>
          <JoinBtn />
        </div>

        {/* -----------------------------footer--------------------------- */}
      </div>
      <Footer />
    </>
  );
};

export default EmergingLeaders;
