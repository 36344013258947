import React from "react";

const TwitterBlack = () => {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15.375" cy="15.498" r="15" fill="#1B1B1B" />
      <path
        d="M23.375 10.9235C22.8234 11.1578 22.2231 11.3265 21.6048 11.3921C22.2467 11.0166 22.7275 10.4233 22.9568 9.7235C22.3544 10.0764 21.6945 10.3238 21.0063 10.4549C20.7187 10.1521 20.3708 9.91085 19.9844 9.74623C19.5979 9.58162 19.1812 9.49714 18.7602 9.49805C17.0566 9.49805 15.6866 10.8578 15.6866 12.5265C15.6866 12.7608 15.7155 12.9951 15.7623 13.2205C13.2116 13.0892 10.9366 11.8892 9.42415 10.0519C9.14857 10.5154 9.00416 11.0432 9.00593 11.5803C9.00593 12.6312 9.54854 13.5578 10.376 14.1028C9.88835 14.0839 9.41215 13.9519 8.9861 13.7176V13.7549C8.9861 15.2265 10.0425 16.446 11.4504 16.7265C11.186 16.7941 10.9141 16.8287 10.641 16.8294C10.4409 16.8294 10.2516 16.8099 10.0605 16.7833C10.4499 17.9833 11.5837 18.8549 12.9339 18.8833C11.8776 19.698 10.5544 20.1773 9.1177 20.1773C8.85992 20.1773 8.62197 20.1685 8.375 20.1401C9.73782 21.001 11.3548 21.498 13.0962 21.498C18.7494 21.498 21.8427 16.8862 21.8427 12.8833C21.8427 12.7519 21.8427 12.6205 21.8337 12.4892C22.4322 12.0578 22.9568 11.5235 23.375 10.9235Z"
        fill="white"
      />
    </svg>
  );
};

export default TwitterBlack;
