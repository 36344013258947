import React from "react";

const MailIcon = () => {
  return (
    <svg
      width="21"
      height="17"
      viewBox="0 0 21 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.375 0.498047H0.375V16.498H20.375V0.498047ZM18.375 4.49805L10.375 9.49805L2.375 4.49805V2.49805L10.375 7.49805L18.375 2.49805V4.49805Z"
        fill="white"
      />
    </svg>
  );
};

export default MailIcon;
