import React from "react";
import styles from "./LoginCards.module.css";
import WeAscendLogoIcon from "../../assets/icons/WeAscendLogoIcon";
import { useNavigate } from "react-router-dom";
import ArrowIcon from "../../assets/icons/ArrowIcon";
import BulbIcon from "../../assets/icons/BulbIcon";
import BootcampIcon from "../../assets/icons/BootcampIcon";
import CompassIcon from "../../assets/icons/CompassIcon";
import AmalBotIcon from "../../assets/icons/AmalBotIcon";
import CircleIcon from "../../assets/icons/CircleIcon";
import CreditIcon from "../../assets/icons/CreditIcon";

const LoginCards = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.mainCont}>
      <div className={styles.logoCont}>
        {" "}
        <WeAscendLogoIcon />
      </div>
      <div className={styles.cardsContainer}>
        {/* <div className={styles.cardsRow1}>
          <div className={styles.card}>card</div>
        </div>
        <div className={styles.cardsRow2}>2</div> */}

        <div className={styles.empCardsCont}>
          <div className={styles.empCardsContRowA}>
            {/* ---------------------card 1------------------------- */}

            <div className={styles.empCardsContRow1}>
              <div>
                <div className={styles.empIconCont}>
                  <BootcampIcon />
                  <div className={styles.aspiringCont}>
                    <span className={styles.aspiringText}>
                      WeAscend Bootcamp
                    </span>
                    <span className={styles.dreamText}>
                      Master Business & Finance 4x Faster
                    </span>
                  </div>
                </div>
                <span className={styles.transformText}>
                  AI Simplifies complex concepts, tailors them to your business
                </span>
              </div>

              <div className={styles.learnMoreEmpCont}>
                <button
                  className={styles.learnMoreEmp}
                  onClick={() => {
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0;
                    navigate("/products/bootcamp");
                  }}
                >
                  Access
                </button>
                <div className={styles.arrowIcon}>
                  <ArrowIcon />
                </div>
              </div>
            </div>

            {/* ---------------------card 2------------------------- */}

            <div className={styles.empCardsContRow1}>
              <div>
                <div className={styles.empIconCont}>
                  <CompassIcon />
                  <div className={styles.aspiringCont}>
                    <span className={styles.aspiringText}>
                      WeAscend Inner Compass
                    </span>
                    <span className={styles.dreamText}>
                      Unleash your Confidence
                    </span>
                  </div>
                </div>
                <span className={styles.transformText}>
                  Unlocking your leadership potential for Deeper Connections
                  with Customers and Teams
                </span>
              </div>

              <div className={styles.learnMoreEmpCont}>
                <button
                  className={styles.learnMoreEmp}
                  onClick={() => {
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                    // Assuming 'navigate' is a function to navigate to a different page
                    navigate("/products/inner-compass");
                  }}
                >
                  Access
                </button>
                <div className={styles.arrowIcon}>
                  <ArrowIcon />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.empCardsContRowB}>
            {/* ---------------------card 3------------------------- */}

            <div className={styles.empCardsContRow1}>
              <div>
                <div className={styles.empIconCont}>
                  <AmalBotIcon />
                  <div className={styles.aspiringCont}>
                    <span className={styles.aspiringText}>
                      WeAscend AMAL Bot
                    </span>
                    <span className={styles.dreamText}>
                      Your AI Biz Bot Bestie
                    </span>
                  </div>
                </div>
                <span className={styles.transformText}>
                  Advice, Pep Talks, Venting, Emotional Support 24/7
                </span>
              </div>
              <div className={styles.learnMoreEmpCont}>
                <button
                  className={styles.learnMoreEmp}
                  onClick={() => {
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                    // Assuming 'navigate' is a function to navigate to a different page
                    navigate("/login");
                  }}
                >
                  Access
                </button>
                <div className={styles.arrowIcon}>
                  <ArrowIcon />
                </div>
              </div>
            </div>

            {/* ---------------------card 4------------------------- */}

            <div className={styles.empCardsContRow1}>
              <div>
                <div className={styles.empIconCont}>
                  <CircleIcon />
                  <div className={styles.aspiringCont}>
                    <span className={styles.aspiringText}>WeAscend Circle</span>
                    <span className={styles.dreamText}>
                      Connect, Celebrate, Succeed
                    </span>
                  </div>
                </div>
                <span className={styles.transformText}>
                  Your tribe of inspiring female entrepreneurs awaits
                </span>
              </div>

              <div className={styles.learnMoreEmpCont}>
                <button
                  className={styles.learnMoreEmp}
                  onClick={() => {
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                    // Assuming 'navigate' is a function to navigate to a different page
                    navigate("/products/circle");
                  }}
                >
                  Access
                </button>
                <div className={styles.arrowIcon}>
                  <ArrowIcon />
                </div>
              </div>
            </div>

            {/* ---------------------card 5------------------------- */}

            <div className={styles.empCardsContRow1}>
              <div>
                <div className={styles.empIconCont}>
                  <CreditIcon />
                  <div className={styles.aspiringCont}>
                    <span className={styles.aspiringText}>WeAscend Credit</span>
                    <span className={styles.dreamText}>
                      Fast Track to Business Funding
                    </span>
                  </div>
                </div>
                <span className={styles.transformText}>
                  Demystify loans, secure financing 2x faster with secure data
                  sharing.
                </span>
              </div>

              <div className={styles.learnMoreEmpCont}>
                <button
                  className={styles.learnMoreEmp}
                  onClick={() => {
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                    // Assuming 'navigate' is a function to navigate to a different page
                    navigate("/products/credit");
                  }}
                >
                  Access
                </button>
                <div className={styles.arrowIcon}>
                  <ArrowIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginCards;
