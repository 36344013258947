import React, { useState, useRef, useEffect, useContext } from "react";
import styles from "./CircularImageComp.module.css";
import creditProfile from "../../assets/images/creditProfile.png";
import { NavLink, useNavigate } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";

const CircularImageComp = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { setIsAuthenticated } = useContext(AuthContext);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const navigate = useNavigate();

  const handleImageHover = () => {
    setIsDropdownOpen(true);
  };

  const handleImageLeave = () => {
    // Remove this function to keep the dropdown open even when the mouse leaves
  };

  const handleImageClick = (event) => {
    event.stopPropagation(); // Prevent the click event from triggering the document click handler
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.setItem("isAuthenticatedLocal", false);
    navigate("/");
  };

  return (
    <div
      className={styles.circularImageWrapper}
      onMouseEnter={handleImageHover}
      onMouseLeave={handleImageLeave}
      onClick={handleImageClick}
    >
      <img className={styles.circularImage} src={creditProfile} alt="" />
      {isDropdownOpen && (
        <div className={styles.dropdown} ref={dropdownRef}>
          <div className={styles.dropdownCont}>
            {/* <p className={styles.dropdownItem}>Account Settings</p> */}
            <NavLink to="/account-settings">
              <p className={styles.dropdownItem}>Account Settings</p>
            </NavLink>

            {/* <NavLink to="/"> */}
            <p className={styles.logoutItem} onClick={handleLogout}>
              Log Out
            </p>
            {/* </NavLink> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default CircularImageComp;
