import React from "react";

const LocationIcon = () => {
  return (
    <svg
      // width="25"
      // height="55"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.375 1.99805C10.1878 2.00063 8.09084 2.87065 6.54423 4.41727C4.99761 5.96388 4.12759 8.0608 4.12501 10.248C4.12239 12.0355 4.70624 13.7744 5.78701 15.198C5.78701 15.198 6.01201 15.4943 6.04876 15.537L12.375 22.998L18.7043 15.5333C18.7373 15.4935 18.963 15.198 18.963 15.198L18.9638 15.1958C20.044 13.7728 20.6276 12.0346 20.625 10.248C20.6224 8.0608 19.7524 5.96388 18.2058 4.41727C16.6592 2.87065 14.5623 2.00063 12.375 1.99805ZM12.375 13.248C11.7817 13.248 11.2016 13.0721 10.7083 12.7425C10.215 12.4128 9.83043 11.9443 9.60337 11.3961C9.37631 10.8479 9.3169 10.2447 9.43265 9.66278C9.54841 9.08083 9.83413 8.54628 10.2537 8.12673C10.6732 7.70717 11.2078 7.42145 11.7897 7.30569C12.3717 7.18994 12.9749 7.24935 13.5231 7.47641C14.0712 7.70347 14.5398 8.08799 14.8694 8.58134C15.1991 9.07468 15.375 9.6547 15.375 10.248C15.374 11.0434 15.0576 11.8059 14.4952 12.3683C13.9328 12.9307 13.1704 13.2471 12.375 13.248Z"
        fill="white"
      />
    </svg>
  );
};

export default LocationIcon;
