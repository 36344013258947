import React from "react";
import LogoIcon from "../../assets/icons/LogoIcon";
import styles from "./NavbarChatbot.module.css";
import { NavLink } from "react-router-dom";
import CircularImageComp from "../circularImageComp/CircularImageComp";
import BellIcon from "../../assets/icons/BellIcon";
import DropdownIcon from "../../assets/icons/DropdownIcon";
import BellIconPink from "../../assets/icons/BellIconPink";

const NavbarChatbot = ({ onLogout }) => {
  return (
    <div className={styles.mainCont}>
      <LogoIcon />
      <div className={styles.navImgComp}>
        <ul className={styles.navLinks}>
          <li>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive
                  ? `${styles.navLink} ${styles.activeLink}`
                  : `${styles.navLink}`
              }
            >
              Home
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/contact-us"
              className={({ isActive }) =>
                isActive
                  ? `${styles.navLink} ${styles.activeLink}`
                  : `${styles.navLinkAmal}`
              }
            >
              AMAL Bot
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact-us"
              className={({ isActive }) =>
                isActive
                  ? `${styles.navLink} ${styles.activeLink}`
                  : `${styles.navLink}`
              }
            >
              Page 1
            </NavLink>
          </li>
        </ul>

        <div className={styles.bellImageCont}>
          <div
          //   className={styles.circularDiv}
          >
            {" "}
            <BellIconPink />
          </div>
          <div className={styles.dropImage}>
            <CircularImageComp onLogout={onLogout} />
            <DropdownIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarChatbot;
