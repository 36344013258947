import React, { useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "./Login.module.css";
import LogoIcon from "../../assets/icons/LogoIcon";
import EyeCloseIcon from "../../assets/icons/EyeCloseIcon";
import EyeOpenIcon from "../../assets/icons/EyeOpenIcon";
import entBgImgMin from "../../assets/images/entBgImgMin.png";
import Navbar from "../../components/navbar/Navbar";
import LogoIconBig from "../../assets/icons/LogoIconBig";
import AuthContext from "../../contexts/AuthContext";

const Login = ({ onLogin }) => {
  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");
  const { setIsAuthenticated } = useContext(AuthContext);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate(); // Hook for navigation

  const handleClick = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if the entered email and password match the dummy values
    if (loginId === "faisal@aawaz.com" && password === "aawazTest8998") {
      setIsAuthenticated(true);
      localStorage.setItem("isAuthenticatedLocal", true);
      // Navigate to the chatbot page if the credentials match
      navigate("/chatbot");
    } else if (loginId === "seemab@aawaz.com" && password === "aawazTest8998") {
      setIsAuthenticated(true);

      localStorage.setItem("isAuthenticatedLocal", true);
      // Navigate to the chatbot page if the credentials match
      navigate("/chatbot");
    } else {
      // Display error if the credentials don't match
      setError("Invalid email or password. Please try again.");
    }
  };

  return (
    <>
      {/* <Navbar /> */}
      <div className={styles.mainCont}>
        <div className={styles.sec2}>
          <div className={styles.logoIconBig}>
            {" "}
            <LogoIconBig />
          </div>
        </div>
        <div className={styles.sec1}>
          <div className={styles.sec1Cont}>
            <img src={entBgImgMin} alt="" />
            {/* <LogoIcon /> */}
            <h1 className={styles.loginText}>Login</h1>
            <form onSubmit={handleSubmit} className={styles.loginForm}>
              <div>
                <h3>Login ID</h3>
                <input
                  type="text"
                  placeholder="Enter your Login ID"
                  value={loginId}
                  onChange={(e) => setLoginId(e.target.value)}
                  className={styles.input}
                />
              </div>
              <div>
                <h3>Password</h3>
                <div className={styles.passEye}>
                  <input
                    type={showPassword ? "text" : "password"}
                    // placeholder="Enter your Password"
                    className={styles.inputPass}
                    value={password}
                    onChange={(e) => {
                      const newPassword = e.target.value;
                      if (/^[a-zA-Z0-9!@#$%^&*]*$/.test(newPassword)) {
                        setPassword(newPassword);
                      }
                    }}
                  />
                  <span onClick={handleClick}>
                    {showPassword ? <EyeCloseIcon /> : <EyeOpenIcon />}
                  </span>
                </div>
              </div>
              <div className={styles.continueBtnCont}>
                <button type="submit" className={styles.createBtn}>
                  Login
                </button>

                {/* <NavLink to={isAuthenticated ? "/chatbot" : "login"}>
                  <button type="submit" className={styles.createBtn}>
                    Login
                  </button>
                </NavLink> */}
              </div>

              {error && <p className={styles.error}>{error}</p>}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
