import React, { useEffect } from "react";
import styles from "./ValuesCards.module.css"; // Import your CSS modules

const ValuesCards = () => {
  useEffect(() => {
    function handleScroll() {
      const cards = document.querySelectorAll(`.${styles.valuesCard}`);

      cards.forEach((card) => {
        const cardTop = card.getBoundingClientRect().top + window.scrollY;
        const windowHeight = window.innerHeight;

        if (
          cardTop < window.scrollY + windowHeight * 0.75 &&
          !card.classList.contains(styles.animateCard)
        ) {
          card.classList.add(styles.animateCard);
        }
      });
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={styles.valuesCardsCont}>
      <div className={`${styles.valuesCard} ${styles.card1}`}>
        <div className={styles.barTextCont}>
          <div className={styles.bar1}></div>
          <span className={styles.barText}>Empathy and Understanding</span>
        </div>
      </div>
      <div className={`${styles.valuesCard} ${styles.card2}`}>
        <div className={styles.barTextCont}>
          <div className={styles.bar2}></div>
          <span className={styles.barText}>
            Ethical Innovation and Transparency
          </span>
        </div>
      </div>
      <div className={`${styles.valuesCard} ${styles.card3}`}>
        <div className={styles.barTextCont}>
          <div className={styles.bar3}></div>
          <span className={styles.barText}>Empowerment and Knowledge</span>
        </div>
      </div>
      <div className={`${styles.valuesCard} ${styles.card4}`}>
        <div className={styles.barTextCont}>
          <div className={styles.bar4}></div>
          <span className={styles.barText}>Audacious Ambition and Support</span>
        </div>
      </div>
    </div>
  );
};

export default ValuesCards;
