import React from "react";

const OvalIcon = () => {
  return (
    <svg
      width="100"
      height="34"
      viewBox="0 0 100 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 7.00114C42.3739 -1.68199 61.6255 0.3411 95 7.00114M98.5 23.5001C60.3415 34.006 38.988 35.7178 1 28.5001"
        stroke="#DFDFDF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-dasharray="4 4"
      />
    </svg>
  );
};

export default OvalIcon;
