import React from "react";

const CbIcon2 = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.15004 0.824125C6.43906 0.962339 4.66823 1.62448 3.30578 2.63698C2.90444 2.93269 2.27075 3.51769 1.9539 3.8809C1.14769 4.8034 0.485834 6.05053 0.211232 7.1691C0.0387259 7.86981 0 8.21374 0 9.01731C0 9.82088 0.0387259 10.1648 0.211232 10.8655C0.489354 12.0066 1.16882 13.273 2.00318 14.2084C2.32707 14.5748 2.96781 15.1534 3.36563 15.4426C4.37603 16.1787 5.74199 16.783 6.96714 17.0337C7.73462 17.1912 8.11131 17.2266 8.99145 17.2266C9.87158 17.2266 10.2483 17.1912 11.0158 17.0337C11.9346 16.8473 13.1386 16.378 13.9308 15.8991C15.3002 15.0666 16.3529 13.9737 17.0746 12.6334C17.6555 11.5502 17.9406 10.5184 17.9935 9.31302C18.0744 7.39089 17.402 5.51054 16.0748 3.93876C15.779 3.5884 14.9376 2.82019 14.5539 2.55019C12.6951 1.24198 10.456 0.637697 8.15004 0.824125ZM10.0124 1.38019C11.0263 1.49912 11.8888 1.73698 12.769 2.14198C15.3355 3.3184 17.057 5.58768 17.3703 8.2041C17.4337 8.72159 17.3985 9.78873 17.3034 10.2869C16.6873 13.5398 13.9448 16.0437 10.3821 16.6062C9.83638 16.693 8.66756 16.7251 8.10075 16.6673C5.23504 16.3812 2.74954 14.8094 1.46102 12.4662C0.859011 11.3734 0.577368 10.2773 0.577368 9.01731C0.577368 8.24588 0.651299 7.71231 0.862531 6.99874C1.62649 4.36947 3.9606 2.25769 6.85448 1.57948C7.67125 1.38662 8.03738 1.34484 8.93864 1.33841C9.3435 1.33841 9.76949 1.35448 10.0124 1.38019Z"
        fill="#454545"
      />
      <path
        d="M10.2227 6.24018C8.68423 6.92161 7.39923 7.50339 7.36755 7.53232C7.27601 7.61589 4.58984 12.6977 4.58984 12.7844C4.59336 12.9066 4.74475 13.0352 4.88909 13.0352C4.97006 13.0352 10.5431 10.5666 10.6135 10.5023C10.705 10.4187 13.3912 5.33697 13.3912 5.25019C13.3877 5.12483 13.2328 4.99947 13.0849 4.99947C13.0497 5.00269 11.7612 5.55876 10.2227 6.24018ZM12.4301 5.9059C12.4054 5.96697 10.353 9.83374 10.3389 9.85303C10.3318 9.85945 9.81432 9.39981 9.19118 8.82767C8.56805 8.25553 8.07517 7.78303 8.09278 7.7766C8.1139 7.77018 9.08557 7.33625 10.2579 6.81875C11.4302 6.29804 12.4019 5.87054 12.4195 5.87054C12.4336 5.86733 12.4406 5.88661 12.4301 5.9059ZM8.79688 9.21017C9.41298 9.77267 9.91289 10.242 9.90585 10.2484C9.846 10.2902 5.56503 12.1673 5.55095 12.1577C5.53335 12.1416 7.62455 8.18482 7.65271 8.1816C7.66327 8.1816 8.17727 8.64446 8.79688 9.21017Z"
        fill="#454545"
      />
    </svg>
  );
};

export default CbIcon2;
