import React from "react";
import styles from "../aspiringEntrepreneurs/AspiringEntrepreneurs.module.css";
import Navbar from "../../components/navbar/Navbar";
import sfMainImg from "../../assets/images/sfMainImg.png";
import backArrow from "../../assets/images/backArrow.png";
import sf2img from "../../assets/images/sf2img.png";
import sf3img from "../../assets/images/sf3img.png";
import sf4img from "../../assets/images/sf4img.png";
import sf5img from "../../assets/images/sf5img.png";
import sf6img from "../../assets/images/sf6img.png";

import Footer from "../../components/footer/Footer";
import JoinBtn from "../../components/joinBtn/JoinBtn";

const StartupFounders = () => {
  return (
    <>
      <Navbar />
      <div className={styles.mainCont}>
        <div className={styles.sec1}>
          <div className={styles.sec1a}>
            <span className={styles.aspiring}>Startup Founders</span>
            <span className={styles.headline}>
              Fuel Your Startup's Ascent with WeAscend
            </span>
            <span className={styles.desc}>
              The ultimate platform crafted for Founders to accelerate growth,
              harness innovative tools, and connect with a vibrant community of
              trailblazers
            </span>
            <div className={styles.ascendBtnWrap}>
              <JoinBtn />
            </div>
          </div>
          <div className={styles.sec1b}>
            <img src={sfMainImg} alt="" style={{ width: "100%" }} />
          </div>
        </div>

        {/* ------------------------------sec2------------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec2b}>
            <img src={sf2img} alt="" style={{ width: "100%" }} />
          </div>
          <div className={styles.sec2a}>
            <span className={styles.title}>WeAscend Bootcamp</span>
            <span className={styles.headline}>From Founder to Force</span>
            <span className={styles.desc}>
              Bootcamp propels your venture with courses tailored for growth,
              covering customer acquisition, financial planning, and product
              development. It's your blueprint for building with precision.
            </span>
          </div>
        </div>

        {/* -----------------------------sec3-------------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec2a}>
            <span className={styles.title}>WeAscend AMAL Bot</span>
            <span className={styles.headline}>
              Operational Excellence, Emotional Resilience
            </span>
            <span className={styles.desc}>
              Your steadfast ally in the startup journey, providing expert
              advice for operational efficiency, strategic customer engagement,
              and unwavering emotional support through every challenge and
              victory
            </span>
          </div>
          <div className={styles.sec3b}>
            <img src={sf3img} alt="" style={{ width: "100%" }} />
          </div>
        </div>

        {/* --------------------------------sec4-------------------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec2b}>
            <img src={sf4img} alt="" style={{ width: "100%" }} />
          </div>
          <div className={styles.sec2a}>
            <span className={styles.title}>WeAscend Inner Compass</span>
            <span className={styles.headline}>
              Embrace Your Journey with Confidence
            </span>
            <span className={styles.desc}>
              Identify your unique style, manage stress, and foster a thriving
              startup culture. It's designed to guide you with clarity and
              resilience, ensuring you lead your team to success and innovation.
            </span>
          </div>
        </div>

        {/* --------------------------------------sec5----------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec2a}>
            <span className={styles.title}>WeAscend Circle</span>
            <span className={styles.headline}>
              Collaborate, Innovate, and Grow Together
            </span>
            <span className={styles.desc}>
              Forge powerful alliances, glean critical market insights, and
              engage in enriching peer-to-peer learning. It's your space to
              connect, grow, and celebrate each milestone with fellow
              trailblazers.
            </span>
          </div>
          <div className={styles.sec3b}>
            <img src={sf5img} alt="" style={{ width: "100%" }} />
          </div>
        </div>

        {/* --------------------------------------sec6-------------------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec2b}>
            <img src={sf6img} alt="" style={{ width: "100%" }} />
          </div>
          <div className={styles.sec2a}>
            <span className={styles.title}>WeAscend Credit</span>
            <span className={styles.headline}>
              Secure Your Venture's Financial Future
            </span>
            <span className={styles.desc}>
              Dive into expert guidance on navigating seed funding, angel
              investment, and venture capital, while honing your skills in
              investor communication and capital allocation.
            </span>
          </div>
        </div>

        <div className={styles.redyToCont}>
          <p className={styles.readyToTurn}>
            Ready to turn your dream into reality? WeAscend offers the tools,
            support, and community you need to succeed. Join WeAscend and
            takethe first step towards the extraordinary!
          </p>
          <JoinBtn />
        </div>

        {/* -----------------------------footer--------------------------- */}
      </div>
      <Footer />
    </>
  );
};

export default StartupFounders;
