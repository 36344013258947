import React from "react";

const BellIconPink = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4071 13.1694V13.8148H0.5V13.1694L0.501759 13.1679C1.04277 12.7166 1.51693 12.1999 1.91099 11.631L1.92496 11.6109L1.93689 11.5894C2.37971 10.7935 2.6462 9.92219 2.71895 9.02678L2.72059 9.00657V8.9863L2.72059 6.56247L2.72059 6.56138C2.71807 5.41222 3.16834 4.294 3.99972 3.41514C4.83212 2.53521 5.99051 1.95556 7.26391 1.79379L7.70089 1.73827V1.29778V0.655021C7.70089 0.628155 7.71213 0.592924 7.74798 0.559968C7.78525 0.525712 7.84416 0.5 7.91351 0.5C7.98286 0.5 8.04177 0.525712 8.07904 0.559967C8.1149 0.592925 8.12613 0.628157 8.12613 0.655021V1.28796V1.73268L8.56781 1.78455C9.85282 1.93546 11.0256 2.51175 11.8692 3.39478C12.7117 4.27672 13.1683 5.40317 13.1652 6.56111V6.56247V8.9863V9.00657L13.1668 9.02678C13.2395 9.92219 13.506 10.7935 13.9488 11.5894L13.9616 11.6124L13.9767 11.6339C14.3773 12.2036 14.8586 12.7199 15.4071 13.1694Z"
        stroke="#FF0071"
      />
    </svg>
  );
};

export default BellIconPink;
