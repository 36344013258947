import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import Navbar from "./components/navbar/Navbar";
import Home from "./pages/home/Home";
import AboutUs from "./pages/aboutUs/AboutUs";
import Products from "./pages/products/Products";
import Users from "./pages/users/Users";
import ForInstitutions from "./pages/forInstitutions/ForInstitutions";
import Resources from "./pages/resources/Resources";
import FinancialInstitutions from "./pages/financialInstitutions/FinancialInstitutions";
import AspiringEntrepreneurs from "./pages/aspiringEntrepreneurs/AspiringEntrepreneurs";
import StartupFounders from "./pages/startupFounders/StartupFounders";
import GrowthStage from "./pages/growthStage/GrowthStage";
import EmergingLeaders from "./pages/emergingLeaders/EmergingLeaders";
import Chatbot from "./pages/chatbot/Chatbot";
import Login from "./pages/login/Login";
import AuthContext, { AuthProvider } from "./contexts/AuthContext";
import Bootcamp from "./pages/bootcamp/Bootcamp";
import InnerCompass from "./pages/innerCompass/InnerCompass";
import AmalBot from "./pages/amalBot/AmalBot";
import Circle from "./pages/circle/Circle";
import Credit from "./pages/credit/Credit";
import LoginCards from "./pages/loginCards/LoginCards";
import JoinWeAscend from "./pages/joinWeAscend/JoinWeAscend";
import OurValues from "./pages/ourValues/OurValues";

function App() {
  const { isAuthenticated } = useContext(AuthContext);

  // console.log("isAuthenticated app", isAuthenticated);
  return (
    <div>
      <BrowserRouter>
        {/* <Navbar /> */}

        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/products" element={<Products />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/users" element={<Users />} />

          <Route path="/about-us" element={<AboutUs />} />
          <Route
            path="/aspiring-entrepreneurs"
            element={<AspiringEntrepreneurs />}
          />
          <Route path="/startup-founders" element={<StartupFounders />} />

          <Route path="/growth-stage" element={<GrowthStage />} />
          <Route path="/emerging-leaders" element={<EmergingLeaders />} />
          <Route path="/login" element={<Login />} />
          <Route path="/login-cards" element={<LoginCards />} />
          {isAuthenticated ? (
            <Route path="/chatbot" element={<Chatbot />} />
          ) : (
            <Route path="/login" element={<Login />} />
          )}

          <Route path="/products/bootcamp" element={<Bootcamp />} />
          <Route path="/products/inner-compass" element={<InnerCompass />} />
          <Route path="/products/amal-bot" element={<AmalBot />} />
          <Route path="/products/circle" element={<Circle />} />
          <Route path="/products/credit" element={<Credit />} />
          <Route
            path="/users/aspiring-entrepreneurs"
            element={<AspiringEntrepreneurs />}
          />
          <Route path="/users/startup-founders" element={<StartupFounders />} />
          <Route
            path="/users/growth-stage-entrepreneurs"
            element={<GrowthStage />}
          />
          <Route
            path="/users/emerging-business-leaders"
            element={<EmergingLeaders />}
          />
          <Route
            path="/financial-institutions"
            element={<FinancialInstitutions />}
          />
          <Route path="/about-us/our-values" element={<OurValues />} />
          <Route path="/join-we-ascend" element={<JoinWeAscend />} />

          <Route path="*" element={<Navigate to="/" replace={true} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
