import React from "react";
import styles from "../aspiringEntrepreneurs/AspiringEntrepreneurs.module.css";
import Navbar from "../../components/navbar/Navbar";
import backArrow from "../../assets/images/backArrow.png";

import gsMainImg from "../../assets/images/gsMainImg.png";
import gs2img from "../../assets/images/gs2img.png";
import gs3img from "../../assets/images/gs3img.png";
import gs4img from "../../assets/images/gs4img.png";
import gs5img from "../../assets/images/gs5img.png";
import gs6img from "../../assets/images/gs6img.png";

import Footer from "../../components/footer/Footer";
import JoinBtn from "../../components/joinBtn/JoinBtn";

const GrowthStage = () => {
  return (
    <>
      <Navbar />
      <div className={styles.mainCont}>
        <div className={styles.sec1}>
          <div className={styles.sec1a}>
            <span className={styles.aspiring}>Growth Stage</span>
            <span className={styles.headline}>
              Elevate Your Enterprise on WeAscend
            </span>
            <span className={styles.desc}>
              Where Growth Stage Entrepreneurs unlock exponential growth through
              bespoke strategies, innovative tools, and an inspiring circle of
              peers
            </span>
            <div className={styles.ascendBtnWrap}>
              <JoinBtn />
            </div>
          </div>
          <div className={styles.sec1b}>
            <img src={gsMainImg} alt="" style={{ width: "100%" }} />
          </div>
        </div>

        {/* ------------------------------sec2------------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec2b}>
            <img src={gs2img} alt="" style={{ width: "100%" }} />
          </div>
          <div className={styles.sec2a}>
            <span className={styles.title}>WeAscend Bootcamp</span>
            <span className={styles.headline}>
              Master Scaling Efficiency, Supercharge Growth
            </span>
            <span className={styles.desc}>
              Sharpen your scaling strategy with modules on efficient
              operations, global market entry, advanced financial forecasting,
              and leadership development. Elevate your venture to manage rapid
              growth.
            </span>
          </div>
        </div>

        {/* -----------------------------sec3-------------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec2a}>
            <span className={styles.title}>WeAscend AMAL Bot</span>
            <span className={styles.headline}>
              Your 24/7 Strategic Partner in Growth
            </span>
            <span className={styles.desc}>
              AMAL offers targeted strategies for operational expansion,
              personal well-being, and leadership evolution, ensuring your
              journey from startup to scale-up is marked by unwavering
              resilience.
            </span>
          </div>
          <div className={styles.sec3b}>
            <img src={gs3img} alt="" style={{ width: "100%" }} />
          </div>
        </div>

        {/* --------------------------------sec4-------------------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec2b}>
            <img src={gs4img} alt="" style={{ width: "100%" }} />
          </div>
          <div className={styles.sec2a}>
            <span className={styles.title}>WeAscend Inner Compass</span>
            <span className={styles.headline}>
              Unleash Your Inner CEO, Amplified
            </span>
            <span className={styles.desc}>
              Elevate your leadership by mastering emotional resilience,
              articulating strategic visions, and steering larger teams with
              precision. Cultivate a culture of innovation confidently.
            </span>
          </div>
        </div>

        {/* --------------------------------------sec5----------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec2a}>
            <span className={styles.title}>WeAscend Circle</span>
            <span className={styles.headline}>Fuel Your Growth, Your Way</span>
            <span className={styles.desc}>
              Your catalyst for explosive growth, delivering precise financial
              strategies and expert guidance to secure vital funding. Navigate
              funding rounds and optimize your capital structure with our
              insights.
            </span>
          </div>
          <div className={styles.sec3b}>
            <img src={gs5img} alt="" style={{ width: "100%" }} />
          </div>
        </div>

        {/* --------------------------------------sec6-------------------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec2b}>
            <img src={gs6img} alt="" style={{ width: "100%" }} />
          </div>
          <div className={styles.sec2a}>
            <span className={styles.title}>WeAscend Credit</span>
            <span className={styles.headline}>
              Gain Tribal Knowledge from Market Leaders
            </span>
            <span className={styles.desc}>
              Engage in deep-dive mastermind sessions, forge strategic
              alliances, and co-create on innovation projects, equipping your
              venture with the insights and agility needed to thrive and
              celebrate.
            </span>
          </div>
        </div>

        <div className={styles.redyToCont}>
          <p className={styles.readyToTurn}>
            Ready to turn your dream into reality? WeAscend offers the tools,
            support, and community you need to succeed. Join WeAscend and
            takethe first step towards the extraordinary!
          </p>
          <JoinBtn />
        </div>

        {/* -----------------------------footer--------------------------- */}
      </div>
      <Footer />
    </>
  );
};

export default GrowthStage;
