import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./LogoIcon.module.css";

const LogoIconBig = () => {
  const navigate = useNavigate();

  return (
    <div onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
      <svg
        //   width="2094"
        height="40"
        viewBox="0 0 2036 420"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1763.15 414.001V379.441L1979.47 119.601L1980.11 133.041H1763.15V92.7207H2028.11V127.281L1809.23 388.401L1808.59 373.681H2035.15V414.001H1763.15Z"
          fill="#1B2A7A"
        />
        <path
          d="M1520.2 419.761C1491.61 419.761 1466.65 413.148 1445.32 399.921C1423.99 386.268 1407.35 367.068 1395.4 342.321C1383.88 317.574 1378.12 287.921 1378.12 253.361C1378.12 218.374 1383.88 188.508 1395.4 163.761C1407.35 139.014 1423.99 120.028 1445.32 106.801C1466.65 93.5743 1491.61 86.9609 1520.2 86.9609C1549.21 86.9609 1574.17 94.4276 1595.08 109.361C1616.41 123.868 1630.49 143.708 1637.32 168.881H1629.64L1636.68 92.7209H1686.6C1685.32 105.094 1684.04 117.681 1682.76 130.481C1681.91 142.854 1681.48 155.014 1681.48 166.961V414.001H1629.64V338.481H1636.68C1629.85 363.654 1615.77 383.494 1594.44 398.001C1573.11 412.508 1548.36 419.761 1520.2 419.761ZM1530.44 377.521C1561.16 377.521 1585.48 367.068 1603.4 346.161C1621.32 324.828 1630.28 293.894 1630.28 253.361C1630.28 212.401 1621.32 181.468 1603.4 160.561C1585.48 139.228 1561.16 128.561 1530.44 128.561C1500.15 128.561 1475.83 139.228 1457.48 160.561C1439.56 181.468 1430.6 212.401 1430.6 253.361C1430.6 293.894 1439.56 324.828 1457.48 346.161C1475.83 367.068 1500.15 377.521 1530.44 377.521Z"
          fill="#1B2A7A"
        />
        <path
          d="M526.551 419.761C497.964 419.761 473.004 413.148 451.671 399.921C430.337 386.268 413.697 367.068 401.751 342.321C390.231 317.574 384.471 287.921 384.471 253.361C384.471 218.374 390.231 188.508 401.751 163.761C413.697 139.014 430.337 120.028 451.671 106.801C473.004 93.5743 497.964 86.9609 526.551 86.9609C555.564 86.9609 580.524 94.4276 601.431 109.361C622.764 123.868 636.844 143.708 643.671 168.881H635.991L643.031 92.7209H692.951C691.671 105.094 690.391 117.681 689.111 130.481C688.257 142.854 687.831 155.014 687.831 166.961V414.001H635.991V338.481H643.031C636.204 363.654 622.124 383.494 600.791 398.001C579.457 412.508 554.711 419.761 526.551 419.761ZM536.791 377.521C567.511 377.521 591.831 367.068 609.751 346.161C627.671 324.828 636.631 293.894 636.631 253.361C636.631 212.401 627.671 181.468 609.751 160.561C591.831 139.228 567.511 128.561 536.791 128.561C506.497 128.561 482.177 139.228 463.831 160.561C445.911 181.468 436.951 212.401 436.951 253.361C436.951 293.894 445.911 324.828 463.831 346.161C482.177 367.068 506.497 377.521 536.791 377.521Z"
          fill="#1B2A7A"
        />
        <path
          d="M142.801 419.761C114.214 419.761 89.254 413.148 67.9207 399.921C46.5874 386.268 29.9474 367.068 18.0007 342.321C6.4807 317.574 0.720703 287.921 0.720703 253.361C0.720703 218.374 6.4807 188.508 18.0007 163.761C29.9474 139.014 46.5874 120.028 67.9207 106.801C89.254 93.5743 114.214 86.9609 142.801 86.9609C171.814 86.9609 196.774 94.4276 217.681 109.361C239.014 123.868 253.094 143.708 259.921 168.881H252.241L259.281 92.7209H309.201C307.921 105.094 306.641 117.681 305.361 130.481C304.507 142.854 304.081 155.014 304.081 166.961V414.001H252.241V338.481H259.281C252.454 363.654 238.374 383.494 217.041 398.001C195.707 412.508 170.961 419.761 142.801 419.761ZM153.041 377.521C183.761 377.521 208.081 367.068 226.001 346.161C243.921 324.828 252.881 293.894 252.881 253.361C252.881 212.401 243.921 181.468 226.001 160.561C208.081 139.228 183.761 128.561 153.041 128.561C122.747 128.561 98.4274 139.228 80.0807 160.561C62.1607 181.468 53.2007 212.401 53.2007 253.361C53.2007 293.894 62.1607 324.828 80.0807 346.161C98.4274 367.068 122.747 377.521 153.041 377.521Z"
          fill="#1B2A7A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1256.13 0H1284.45C1321.21 0 1351 29.7937 1351 66.5461C1351 77.5647 1348.32 87.958 1343.58 97.1105L1215.07 365.295C1215.07 365.295 1198.22 395.037 1182.01 403.357C1148.91 420.35 1108.3 407.287 1091.3 374.181L1018.26 231.875C1001.26 198.77 1014.33 158.157 1047.43 141.164C1080.53 124.171 1121.15 137.233 1138.14 170.338L1211.19 312.645C1211.88 313.993 1212.52 315.352 1213.11 316.722L1304.27 130.26C1297.93 132.27 1291.46 133.092 1284.45 133.092H1256.13C1219.38 133.092 1189.59 103.298 1189.59 66.5461C1189.59 29.7937 1219.38 0 1256.13 0Z"
          fill="#FF0071"
        />
        <path
          d="M793.624 8.11765C760.519 25.1105 747.457 65.7233 764.45 98.8287L907.242 377.015C924.235 410.121 964.848 423.181 997.953 406.19C1031.06 389.197 1044.12 348.584 1027.13 315.478L884.335 37.292C867.342 4.1865 826.73 -8.87524 793.624 8.11765Z"
          fill="#FF0071"
        />
      </svg>
    </div>
  );
};

export default LogoIconBig;
