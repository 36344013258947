import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./LogoIcon.module.css";

const LogoIconWhite = () => {
  const navigate = useNavigate();
  return (
    <div onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
      <svg
        //   width="2094"
        height="20"
        viewBox="0 0 2035 420"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1762.43 414V379.44L1978.75 119.6L1979.39 133.04H1762.43V92.7197H2027.39V127.28L1808.51 388.4L1807.87 373.68H2034.43V414H1762.43Z"
          fill="white"
        />
        <path
          d="M1519.48 419.76C1490.89 419.76 1465.93 413.147 1444.6 399.92C1423.27 386.267 1406.63 367.067 1394.68 342.32C1383.16 317.573 1377.4 287.92 1377.4 253.36C1377.4 218.373 1383.16 188.507 1394.68 163.76C1406.63 139.013 1423.27 120.027 1444.6 106.8C1465.93 93.5733 1490.89 86.96 1519.48 86.96C1548.49 86.96 1573.45 94.4266 1594.36 109.36C1615.69 123.867 1629.77 143.707 1636.6 168.88H1628.92L1635.96 92.72H1685.88C1684.6 105.093 1683.32 117.68 1682.04 130.48C1681.19 142.853 1680.76 155.013 1680.76 166.96V414H1628.92V338.48H1635.96C1629.13 363.653 1615.05 383.493 1593.72 398C1572.39 412.507 1547.64 419.76 1519.48 419.76ZM1529.72 377.52C1560.44 377.52 1584.76 367.067 1602.68 346.16C1620.6 324.827 1629.56 293.893 1629.56 253.36C1629.56 212.4 1620.6 181.467 1602.68 160.56C1584.76 139.227 1560.44 128.56 1529.72 128.56C1499.43 128.56 1475.11 139.227 1456.76 160.56C1438.84 181.467 1429.88 212.4 1429.88 253.36C1429.88 293.893 1438.84 324.827 1456.76 346.16C1475.11 367.067 1499.43 377.52 1529.72 377.52Z"
          fill="white"
        />
        <path
          d="M525.83 419.76C497.243 419.76 472.283 413.147 450.95 399.92C429.617 386.267 412.977 367.067 401.03 342.32C389.51 317.573 383.75 287.92 383.75 253.36C383.75 218.373 389.51 188.507 401.03 163.76C412.977 139.013 429.617 120.027 450.95 106.8C472.283 93.5733 497.243 86.96 525.83 86.96C554.843 86.96 579.803 94.4266 600.71 109.36C622.043 123.867 636.123 143.707 642.95 168.88H635.27L642.31 92.72H692.23C690.95 105.093 689.67 117.68 688.39 130.48C687.537 142.853 687.11 155.013 687.11 166.96V414H635.27V338.48H642.31C635.483 363.653 621.403 383.493 600.07 398C578.737 412.507 553.99 419.76 525.83 419.76ZM536.07 377.52C566.79 377.52 591.11 367.067 609.03 346.16C626.95 324.827 635.91 293.893 635.91 253.36C635.91 212.4 626.95 181.467 609.03 160.56C591.11 139.227 566.79 128.56 536.07 128.56C505.777 128.56 481.457 139.227 463.11 160.56C445.19 181.467 436.23 212.4 436.23 253.36C436.23 293.893 445.19 324.827 463.11 346.16C481.457 367.067 505.777 377.52 536.07 377.52Z"
          fill="white"
        />
        <path
          d="M142.08 419.76C113.493 419.76 88.5333 413.147 67.2 399.92C45.8667 386.267 29.2267 367.067 17.28 342.32C5.76 317.573 0 287.92 0 253.36C0 218.373 5.76 188.507 17.28 163.76C29.2267 139.013 45.8667 120.027 67.2 106.8C88.5333 93.5733 113.493 86.96 142.08 86.96C171.093 86.96 196.053 94.4266 216.96 109.36C238.293 123.867 252.373 143.707 259.2 168.88H251.52L258.56 92.72H308.48C307.2 105.093 305.92 117.68 304.64 130.48C303.787 142.853 303.36 155.013 303.36 166.96V414H251.52V338.48H258.56C251.733 363.653 237.653 383.493 216.32 398C194.987 412.507 170.24 419.76 142.08 419.76ZM152.32 377.52C183.04 377.52 207.36 367.067 225.28 346.16C243.2 324.827 252.16 293.893 252.16 253.36C252.16 212.4 243.2 181.467 225.28 160.56C207.36 139.227 183.04 128.56 152.32 128.56C122.027 128.56 97.7067 139.227 79.36 160.56C61.44 181.467 52.48 212.4 52.48 253.36C52.48 293.893 61.44 324.827 79.36 346.16C97.7067 367.067 122.027 377.52 152.32 377.52Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1255.41 0H1283.73C1320.48 0 1350.28 29.7937 1350.28 66.5461C1350.28 77.5647 1347.6 87.958 1342.86 97.1105L1214.35 365.295C1214.35 365.295 1197.5 395.037 1181.29 403.357C1148.19 420.35 1107.58 407.287 1090.58 374.181L1017.54 231.875C1000.54 198.77 1013.6 158.157 1046.71 141.164C1079.81 124.171 1120.43 137.233 1137.42 170.338L1210.47 312.645C1211.16 313.993 1211.8 315.352 1212.39 316.722L1303.55 130.26C1297.21 132.27 1290.74 133.092 1283.73 133.092H1255.41C1218.66 133.092 1188.87 103.298 1188.87 66.5461C1188.87 29.7937 1218.66 0 1255.41 0Z"
          fill="#FF0071"
        />
        <path
          d="M792.903 8.11765C759.798 25.1105 746.736 65.7233 763.729 98.8287L906.522 377.015C923.514 410.121 964.127 423.181 997.233 406.19C1030.34 389.197 1043.4 348.584 1026.41 315.478L883.615 37.292C866.621 4.18651 826.009 -8.87524 792.903 8.11765Z"
          fill="#FF0071"
        />
      </svg>
    </div>
  );
};

export default LogoIconWhite;
