import React, { useState } from "react";
import styles from "./Footer.module.css";
import LogoIcon from "../../assets/icons/LogoIcon";
import FbIcon from "../../assets/icons/FbIcon";
import TwitterIcon from "../../assets/icons/TwitterBlack";
import InstaIcon from "../../assets/icons/InstaWhite";
import aawazWhiteLogo from "../../assets/images/aawazWhiteLogo.png";
import OvalSmIcon from "../../assets/icons/OvalSmIcon";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import ArrowIcon from "../../assets/icons/ArrowIcon";
import close from "../../assets/images/close.png";
import subImg from "../../assets/images/subImg.png";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  const navigate = useNavigate();

  // console.log("apiBaseURL", apiBaseURL);

  const handleSubscription = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(
        `${apiBaseURL}/Subscribers/subscribe/postEmail`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      console.log("response", response);

      if (response?.status === 200) {
        setShowModal(true);
        setEmail("");
      } else {
        throw new Error("Failed to subscribe");
      }
    } catch (error) {
      // Handle error, e.g., show an error message
      console.error("Failed to subscribe:", error?.message);
      alert(error?.message);
    } finally {
      setLoading(false); // Reset loading state regardless of success or failure
    }
  };

  return (
    <>
      {/* -------------------------------------------------------Join The Aawaz Community------------------ */}

      <div className={styles.joinCont}>
        <span className={styles.joinText}>Join The Aawaz Community</span>
        <p className={styles.joinPara}>
          Stay in the loop with exclusive updates, events, and offers by
          subscribing today
        </p>
        <form className={styles.emailSubCont} onSubmit={handleSubscription}>
          <div className={styles.emailSub1}>
            <input
              type="email"
              placeholder="Enter your email"
              className={styles.input}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className={styles.emailSub2}>
            <button type="submit" className={styles.subBtn}>
              Subscribe
            </button>
          </div>
        </form>

        {loading && <div className={styles.loader}></div>}

        <Modal
          isOpen={showModal}
          onRequestClose={() => setShowModal(false)}
          contentLabel="Correct Input Data Modal"
          className={styles.modal}
        >
          <div className={styles.modalCont}>
            <div
              className={styles.crossIconCont}
              onClick={() => setShowModal(false)}
            >
              <img src={close} alt="" />
            </div>
            <div className={styles.sureCont}>
              <div className={styles.sureTextCont}>
                <p>Welcome Aboard the Aawaz Community!</p>
              </div>
            </div>
            <div className={styles.subImgCont}>
              {" "}
              <img src={subImg} alt="" />
            </div>
            <p className={styles.text}>
              Your journey with us begins now. We're thrilled to have you as
              part of our vibrant family of voices. Together, let's make waves
              and create unforgettable moments. Thank you for choosing to be
              with us!
            </p>
            {/* Add any additional actions or content needed in the modal */}
          </div>
        </Modal>

        {/* <span
                className={styles.closeBtn}
                onClick={() => setShowModal(false)}
              >
                &times;
              </span> */}

        {/* {showModal && (
          <div className={styles.modal}>
            <div className={styles.modalContent}>

              <p className={styles.subPara}>
                You have subscribed successfully!
              </p>
            </div>
          </div>
        )} */}
      </div>

      <div className={styles.footerCont}>
        <div className={styles.menusSec}>
          <div className={styles.menuSec}>
            <div className={styles.menuSecInner}>
              <span className={styles.menuHeading}>Products</span>
              <a
                onClick={() => {
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                  // Assuming 'navigate' is a function to navigate to a different page
                  navigate("/products/bootcamp");
                }}
              >
                Bootcamp
              </a>
              <a
                onClick={() => {
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                  // Assuming 'navigate' is a function to navigate to a different page
                  navigate("/products/inner-compass");
                }}
              >
                Inner Compass
              </a>
              <a
                onClick={() => {
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                  // Assuming 'navigate' is a function to navigate to a different page
                  navigate("/products/amal-bot");
                }}
              >
                AMAL Bot
              </a>
              <a
                onClick={() => {
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                  // Assuming 'navigate' is a function to navigate to a different page
                  navigate("/products/circle");
                }}
              >
                Circle
              </a>
              <a
                onClick={() => {
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                  // Assuming 'navigate' is a function to navigate to a different page
                  navigate("/products/credit");
                }}
              >
                Credit
              </a>
            </div>
          </div>
          <div className={styles.menuSec}>
            <div className={styles.menuSecInner}>
              <span className={styles.menuHeading}>Users</span>
              <a
                onClick={() => {
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                  // Assuming 'navigate' is a function to navigate to a different page
                  navigate("/users/aspiring-entrepreneurs");
                }}
              >
                Aspiring Entrepreneurs
              </a>
              <a
                onClick={() => {
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                  // Assuming 'navigate' is a function to navigate to a different page
                  navigate("/users/startup-founders");
                }}
              >
                Startup Founders
              </a>
              <a
                onClick={() => {
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                  // Assuming 'navigate' is a function to navigate to a different page
                  navigate("/users/growth-stage-entrepreneurs");
                }}
              >
                Growth Stage
              </a>
              <a
                onClick={() => {
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                  // Assuming 'navigate' is a function to navigate to a different page
                  navigate("/users/emerging-business-leaders");
                }}
              >
                Emerging Leaders
              </a>
            </div>
          </div>
          <div className={styles.menuSec}>
            <div className={styles.menuSecInner}>
              <a
                onClick={() => {
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                  // Assuming 'navigate' is a function to navigate to a different page
                  navigate("/financial-institutions");
                }}
                className={styles.menuHeading}
              >
                Financial Institutions
              </a>
            </div>
          </div>

          <div className={styles.menuSec}>
            <div className={styles.menuSecInner}>
              <span className={styles.menuHeading}>About Us</span>
              <a
                onClick={() => {
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                  // Assuming 'navigate' is a function to navigate to a different page
                  navigate("/about-us/our-values");
                }}
              >
                Our Values
              </a>
            </div>
          </div>
        </div>

        <div className={styles.footerBottomSec}>
          <img className={styles.aawazWhiteLogo} src={aawazWhiteLogo} alt="" />
          <div className={styles.smIconsCont}>
            <FbIcon />
            <TwitterIcon />
            <InstaIcon />
            <OvalSmIcon />
          </div>
          <p className={styles.copyrights}>
            © 2024 Aawaz AI. All rights reserved
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
