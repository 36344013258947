import React from "react";
import styles from "../bootcamp/Bootcamp.module.css";
import Navbar from "../../components/navbar/Navbar";
import c1 from "../../assets/images/c1.png";
import c2 from "../../assets/images/c2.png";
import c3 from "../../assets/images/c3.png";
import weAscendLogoW from "../../assets/images/weAscendLogoW.png";
import backArrow from "../../assets/images/backArrow.png";
import courseComp from "../../assets/images/courseComp.png";
import innerCompassImg from "../../assets/images/innerCompassImg.png";
import amalBotImg from "../../assets/images/amalBotImg.png";
import circleImg from "../../assets/images/circleImg.png";
import Footer from "../../components/footer/Footer";
import JoinBtn from "../../components/joinBtn/JoinBtn";

const Circle = ({ isScrolledToDiv }) => {
  return (
    <>
      <Navbar isScrolledToDiv={isScrolledToDiv} />
      <div className={styles.mainCont}>
        <div className={styles.sec1}>
          <div className={styles.sec1a}>
            <span className={styles.aspiring}>Circle</span>
            <span className={styles.headline}>
              Connect, Celebrate, Succeed: Your Tribe of Inspiring
              FemaleEntrepreneurs Awaits.
            </span>

            <div className={styles.ascendBtnWrap}>
              <div className={styles.ascendBtnWrap}>
                <JoinBtn />
              </div>
            </div>
          </div>
          <div className={styles.sec1b}>
            <img src={c1} alt="" style={{ width: "100%" }} />
          </div>
        </div>

        {/* -------------------------------------------------------------- */}

        <div className={styles.unlockParaCont}>
          <p className={styles.unlockParaText}>
            WeAscend Circle is your safe space to connect, collaborate, and
            thrive as a female entrepreneur. Find mentors, access resources, and
            unlock opportunities in asupportive community of inspiring women.
          </p>
        </div>

        {/* ------------------------------sec2------------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec2a}>
            <span className={styles.title}>Benefits</span>
            <span className={styles.headline}>
              <strong>Break the isolation:</strong> Find a supportive community
              of female entrepreneurs who understand your journey.
            </span>
            <span className={styles.headline}>
              <strong>Unlock opportunities:</strong> Build valuable connections,
              access resources, and navigate challenges together.
            </span>
            <span className={styles.headline}>
              <strong>Empower your success:</strong> Find inspiration,
              mentorship, and collaboration to fuel your growth.
            </span>
          </div>

          <div className={styles.sec2b}>
            <img src={c2} alt="" style={{ width: "100%" }} />
          </div>
        </div>

        {/* -----------------------------sec3-------------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec3b}>
            <img src={c3} alt="" style={{ width: "100%" }} />
          </div>
          <div className={styles.sec2a}>
            <span className={styles.title}>Features</span>
            <span className={styles.headline}>
              <strong>Join targeted discussions:</strong> Engage in industry and
              stage-specific groups for focused learning and support.
            </span>
            <span className={styles.headline}>
              <strong>Collaborate on projects:</strong> Spark innovation and
              unlock new opportunities through joint ventures.
            </span>
            <span className={styles.headline}>
              <strong>Attend exclusive events:</strong>Network in person or
              virtually with inspiring female entrepreneurs.
            </span>
          </div>
        </div>

        {/* ----------------------------------------------------------------------------------- */}

        <div className={styles.howItWorksCont}>
          <span className={styles.worksText}>How It Works</span>
          <div className={styles.howItWorksCardsCont}>
            <div className={styles.howItWorksCard}>
              <div>
                {" "}
                <span className={styles.orangeText}>1</span>
              </div>
              <span className={styles.worksTitle}>Join The Circle</span>
              <span className={styles.worksDesc}>
                Sign up and join a vibrant community of women entrepreneurs.
              </span>
            </div>
            <div className={styles.howItWorksCard}>
              <div>
                {" "}
                <span className={styles.orangeText}>2</span>
              </div>
              <span className={styles.worksTitle}>Engage And Share</span>
              <span className={styles.worksDesc}>
                Join discussions, share your experiences, and gain inspiration
                from the community's wisdom.
              </span>
            </div>
            <div className={styles.howItWorksCard}>
              <div>
                {" "}
                <span className={styles.orangeText}>3</span>
              </div>
              <span className={styles.worksTitle}>
                Discover And Collaborate
              </span>
              <span className={styles.worksDesc}>
                Find mentors, collaborators, and potential investors through
                networking opportunities.
              </span>
            </div>
          </div>
        </div>

        <div className={styles.redyToCont}>
          <div className={styles.weAscendLogoWcont}>
            {" "}
            <img src={weAscendLogoW} alt="" />
          </div>
          <p className={styles.readyToTurn}>
            Take the next step in your entrepreneurial journey with WeAscend
            Circle. Join our platform today and tap into a world of inspiration,
            support, and collaboration. Discover your tribe, share your story,
            and ascend together.
          </p>
          <JoinBtn />
        </div>

        {/* -----------------------------footer--------------------------- */}
      </div>
      <Footer />
    </>
  );
};

export default Circle;
