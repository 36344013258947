import React from "react";

const CbIcon1 = () => {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0783 0.901599C9.21156 1.33495 8.57876 1.49545 8.52251 1.54681C8.43111 1.62064 8.41353 1.78114 8.48736 1.8646C8.50845 1.89349 8.82485 2.11177 9.19047 2.35573C9.55257 2.59648 9.84788 2.79871 9.84788 2.80192C9.84788 2.80513 9.7846 3.09724 9.70725 3.44713C9.62991 3.79702 9.56663 4.15333 9.56663 4.23679C9.56663 4.92694 10.6986 5.83857 11.9748 6.17562C12.706 6.36822 13.6341 6.40353 14.3161 6.25908C15.1353 6.08895 15.7751 5.71338 15.9122 5.32498C15.9298 5.28004 16.0071 4.94941 16.088 4.58989L16.2321 3.93184L17.0372 3.74566C17.7051 3.58837 17.8598 3.54343 17.9266 3.47602C18.0321 3.37009 18.025 3.26095 17.909 3.14539C17.8528 3.09082 17.0055 2.50981 16.0177 1.85497C13.6095 0.24676 13.7185 0.31738 13.6306 0.32059C13.5919 0.3238 12.4424 0.58381 11.0783 0.901599ZM15.3181 2.0251C16.6786 2.93353 17.0442 3.19354 16.995 3.2128C16.9598 3.22564 16.0177 3.44713 14.8997 3.70393L12.8712 4.17259L12.0345 3.61405C11.5775 3.3091 10.7936 2.78587 10.2944 2.45203C9.70374 2.0572 9.4014 1.83892 9.43304 1.82287C9.45765 1.81003 10.3014 1.61101 11.3068 1.37989C12.3123 1.14877 13.2158 0.940119 13.3107 0.914439C13.4091 0.88876 13.5076 0.86629 13.5322 0.86629C13.5568 0.86308 14.3618 1.38631 15.3181 2.0251ZM11.5072 3.89974C12.126 4.31383 12.6673 4.67014 12.713 4.6894C12.7728 4.71508 13.0857 4.65088 14.1896 4.39729C14.9595 4.21753 15.5958 4.07629 15.6028 4.08592C15.6099 4.09234 15.5677 4.33951 15.5044 4.63483C15.3989 5.13559 15.3849 5.18374 15.2759 5.29288C14.8786 5.69412 13.6306 5.93487 12.6884 5.79363C11.7041 5.64597 10.8393 5.22226 10.333 4.64125C10.108 4.38124 10.1045 4.32346 10.2346 3.69109C10.2979 3.38935 10.3576 3.14218 10.3647 3.14218C10.3717 3.14218 10.885 3.48244 11.5072 3.89974Z"
        fill="#454545"
      />
      <path
        d="M2.22504 4.21078C2.0352 4.27498 1.86294 4.42585 1.77505 4.6024L1.69068 4.76932L1.69771 8.83638L1.70825 12.9002H0.941865H0.175476L0.0911029 12.9805C-0.147954 13.1956 0.112196 13.9114 0.537577 14.2196C0.871554 14.4603 0.210632 14.441 8.72177 14.441C15.3978 14.441 16.3962 14.4346 16.5509 14.3929C17.022 14.2645 17.3806 13.8119 17.4298 13.279C17.4474 13.08 17.4438 13.0607 17.3595 12.9837L17.2681 12.9002H16.5017H15.7353L15.7458 9.77049L15.7529 6.63754L15.6685 6.5605C15.5525 6.45457 15.3908 6.45457 15.2783 6.5605L15.1904 6.63754V9.69024V12.743L15.1025 12.82L15.0181 12.9002H8.72177H2.42543L2.34105 12.82L2.25316 12.743V8.78181V4.82068L2.33402 4.75327L2.41488 4.68265H5.5859C7.83234 4.68265 8.77802 4.67302 8.83778 4.64734C9.01005 4.57672 9.05223 4.37128 8.91513 4.2493L8.83075 4.16905L5.5859 4.17226C3.18127 4.17226 2.31293 4.1851 2.22504 4.21078ZM16.8427 13.4299C16.8427 13.4395 16.811 13.5005 16.7759 13.5679C16.695 13.7124 16.5439 13.8472 16.4103 13.8921C16.2697 13.9403 1.17389 13.9403 1.03327 13.8921C0.899679 13.8472 0.74851 13.7124 0.667653 13.5679C0.632497 13.5005 0.600857 13.4395 0.600857 13.4299C0.600857 13.4203 4.25703 13.4138 8.72177 13.4138C13.19 13.4138 16.8427 13.4203 16.8427 13.4299Z"
        fill="#454545"
      />
      <path
        d="M6.82422 6.44748C6.75391 6.51168 6.75391 6.55341 6.75391 8.91597V11.3235L6.84531 11.3877C6.89804 11.4262 6.9789 11.4551 7.0246 11.4551C7.10898 11.4551 11.869 9.19845 11.9956 9.09894C12.0905 9.02832 12.1186 8.89029 12.0589 8.7972C11.9886 8.68164 7.1828 6.38328 7.01406 6.38328C6.94023 6.38328 6.8664 6.40896 6.82422 6.44748ZM9.6472 8.16483C10.4909 8.56929 11.1835 8.90955 11.1835 8.91918C11.1835 8.92881 10.5155 9.25302 9.69994 9.64143C8.88081 10.0298 8.01247 10.4439 7.76638 10.5627L7.31639 10.7778V8.91597V7.05096L7.71365 7.24035C7.9281 7.34628 8.79995 7.76037 9.6472 8.16483Z"
        fill="#454545"
      />
    </svg>
  );
};

export default CbIcon1;
