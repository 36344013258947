import React, { createContext, useState } from "react";

// Create the authentication context
const AuthContext = createContext();

// Create the authentication provider component
export const AuthProvider = ({ children }) => {
  const isAuthenticatedLocal = localStorage.getItem("isAuthenticatedLocal");
  const [isAuthenticated, setIsAuthenticated] = useState(
    isAuthenticatedLocal === "true"
  );

  // console.log("isAuthenticated", isAuthenticated);
  // console.log("isAuthenticatedLocal", isAuthenticatedLocal);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

// Export the authentication context
export default AuthContext;
