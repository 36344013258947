import React from "react";
import styles from "./JoinBtn.module.css";
import backArrow from "../../assets/images/backArrow.png";
import { useNavigate } from "react-router-dom";

const JoinBtn = () => {
  const navigate = useNavigate();
  return (
    <div
      className={styles.ascendBtnCont}
      onClick={() => {
        document.body.scrollTop = 40; // For Safari
        document.documentElement.scrollTop = 40; // For Chrome, Firefox, IE, and Opera
        // Assuming 'navigate' is a function to navigate to a different page
        navigate("/join-we-ascend");
      }}
    >
      <span className={styles.joinBtnText}>Join WeAscend</span>
      <img className={styles.backArrow} src={backArrow} alt="" />
    </div>
  );
};

export default JoinBtn;
