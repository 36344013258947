import React from "react";

const CompassIcon = () => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.3557 0.117817C20.0326 0.588745 14.5234 2.84482 10.2847 6.29465C9.03604 7.30222 7.06454 9.29545 6.07879 10.533C3.57061 13.6762 1.51148 17.9255 0.657167 21.7367C0.120481 24.1242 0 25.2961 0 28.034C0 30.772 0.120481 31.9438 0.657167 34.3313C1.52244 38.2192 3.63632 42.5343 6.23213 45.7212C7.23979 46.9697 9.23319 48.9411 10.4709 49.9267C13.6143 52.4347 17.864 54.4937 21.6755 55.3479C24.0633 55.8845 25.2352 56.005 27.9734 56.005C30.7116 56.005 31.8835 55.8845 34.2712 55.3479C37.1299 54.7127 40.8758 53.1137 43.3401 51.4819C47.6008 48.6454 50.8756 44.9218 53.121 40.3548C54.9282 36.6641 55.8154 33.1485 55.9796 29.0416C56.2316 22.4924 54.1396 16.0856 50.0104 10.7301C49.0903 9.53639 46.4726 6.9189 45.2788 5.99895C39.4957 1.54155 32.5297 -0.517391 25.3557 0.117817ZM31.1497 2.01248C34.3041 2.4177 36.9875 3.22813 39.7257 4.60807C47.7103 8.61644 53.0662 16.3484 54.041 25.2632C54.2382 27.0265 54.1286 30.6625 53.8329 32.36C51.9162 43.4433 43.3839 51.9747 32.2997 53.8913C30.6021 54.187 26.9657 54.2965 25.2023 54.0994C16.2868 53.1247 8.55412 47.7692 4.5454 39.7853C2.67248 36.0617 1.79626 32.3271 1.79626 28.034C1.79626 25.4056 2.02626 23.5876 2.68343 21.1563C5.06018 12.1977 12.3219 5.00233 21.3251 2.6915C23.8661 2.03439 25.0052 1.89201 27.8091 1.87011C29.0687 1.87011 30.394 1.92487 31.1497 2.01248Z"
        fill="#000000"
      />
      <path
        d="M31.8067 18.5716C27.0203 20.8934 23.0225 22.8757 22.924 22.9743C22.6392 23.259 14.2822 40.5739 14.2822 40.8696C14.2932 41.2857 14.7641 41.7238 15.2132 41.7238C15.4651 41.7238 32.8034 33.3128 33.0224 33.0938C33.3072 32.809 41.6642 15.4942 41.6642 15.1985C41.6532 14.7714 41.1713 14.3442 40.7113 14.3442C40.6017 14.3552 36.593 16.2499 31.8067 18.5716ZM38.6741 17.4327C38.5974 17.6407 32.2119 30.8158 32.1681 30.8815C32.1462 30.9034 30.5361 29.3373 28.5975 27.3879C26.6589 25.4384 25.1255 23.8285 25.1802 23.8066C25.246 23.7847 28.2689 22.3062 31.9162 20.543C35.5635 18.7688 38.5864 17.3122 38.6412 17.3122C38.685 17.3012 38.7069 17.3669 38.6741 17.4327ZM27.3708 28.6911C29.2875 30.6077 30.8428 32.2067 30.8209 32.2286C30.6347 32.371 17.3161 38.7668 17.2723 38.734C17.2176 38.6792 23.7235 25.1975 23.8111 25.1866C23.844 25.1866 25.4431 26.7636 27.3708 28.6911Z"
        fill="#000000"
      />
    </svg>
  );
};

export default CompassIcon;
