import React, { useEffect, useState } from "react";
import styles from "./Home.module.css";
import entBgImgMin from "../../assets/images/entBgImgMin.png";
import adb from "../../assets/images/adb.png";
import victory from "../../assets/images/victory.jpg";
import backArrow from "../../assets/images/backArrow.png";
import WeAscendLogoIcon from "../../assets/icons/WeAscendLogoIcon";
import Icon4x from "../../assets/icons/Icon4x";
import lock from "../../assets/images/lock.png";
import creditProfile from "../../assets/images/creditProfile.png";
import quotes from "../../assets/images/quotes.png";
import compassImg from "../../assets/images/compassImg.png";
import reviewsprofile1 from "../../assets/images/reviewsprofile1.png";
import reviewsprofile2 from "../../assets/images/reviewsprofile2.png";
import reviewsprofile3 from "../../assets/images/reviewsprofile3.png";
import reviewsprofile4 from "../../assets/images/reviewsprofile4.png";
import reviewsprofile5 from "../../assets/images/reviewsprofile5.png";
import graphImg from "../../assets/images/graphImg.png";
import OvalIcon from "../../assets/icons/OvalIcon";
import GrowthIcon from "../../assets/icons/GrowthIcon";
import BulbIcon from "../../assets/icons/BulbIcon";
import ValuesCards from "../../components/valuesCards/ValuesCards";
import Footer from "../../components/footer/Footer";
import ImageSlider from "../../components/imageSlider/ImageSlider";
import Navbar from "../../components/navbar/Navbar";
import VictoryCircularText from "../../assets/icons/VictoryCircularText";
import amalMainImage from "../../assets/images/amalMainImage.png";
import entBgImgMin3 from "../../assets/images/entBgImgMin3.png";
import womenInCirclesPlus from "../../assets/images/womenInCirclesPlus.png";
import bootcampMainImage from "../../assets/images/bootcampMainImage.png";
import lockProfileImg from "../../assets/images/lockProfileImg.png";
import weAscendLogoImg from "../../assets/images/weAscendLogoImg.png";
import WeAscendMobileComp from "../../components/weAscendMobileComp/WeAscendMobileComp";
import { useNavigate } from "react-router-dom";
import JoinBtn from "../../components/joinBtn/JoinBtn";
import ArrowIcon from "../../assets/icons/ArrowIcon";
import ArrowIconWhite from "../../assets/icons/ArrowIconWhite";
import SfIcon from "../../assets/icons/SfIcon";
import GrowthStageIcon from "../../assets/icons/GrowthStageIcon";
import CrownIcon from "../../assets/icons/CrownIcon";
import EmerBizIcon from "../../assets/icons/EmerBizIcon";

const Home = () => {
  const [isScrolledToDiv, setIsScrolledToDiv] = useState(false);

  const navigate = useNavigate();

  console.log("test isScrolledToDiv", isScrolledToDiv);

  useEffect(() => {
    const handleScroll = () => {
      const targets = [
        document.querySelector(`.${styles.ourValuesCont}`),
        document.querySelector(`.${styles.weAscendCont}`),
        // document.querySelector(`.${styles.footerCont}`),
      ];
      const windowHeight = window.innerHeight;
      let anyTargetInView = false;

      targets.forEach((targetDiv) => {
        if (targetDiv) {
          const targetDivPosition = targetDiv.getBoundingClientRect().top;
          if (
            targetDivPosition <= 0 &&
            targetDivPosition + targetDiv.clientHeight > 0
          ) {
            anyTargetInView = true;
          }
        }
      });

      setIsScrolledToDiv(anyTargetInView);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={styles.mainCont}>
      <Navbar isScrolledToDiv={isScrolledToDiv} />
      <div className={`${styles.imgCont} ${styles.visiblityDesktop}`}>
        <img src={entBgImgMin} alt="" className={styles.mainImage} />
      </div>

      <div className={styles.visiblityMobile}>
        <img src={entBgImgMin3} alt="" className={styles.mainImage} />
      </div>

      <div className={styles.sec1}>
        <div className={styles.spansCont}>
          <span className={styles.fuelHeading1}>Fuel Your</span>
          {/* &nbsp; &nbsp; */}
          <span className={styles.fuelHeading2}>Entrepreneurial Ascent</span>
        </div>

        <JoinBtn />

        <ImageSlider />

        <div className={`${styles.weAscendCont} ${styles.visiblityDesktop}`}>
          <div className={styles.weAscend1}>
            <div className={styles.weAscend1Top}>
              <div className={styles.weAscend1Top1}>
                <div className={`${styles.bootcampCont} ${styles.cardWhite}`}>
                  <span className={styles.title}>WeAscend Bootcamp</span>
                  <span className={styles.headline}>
                    Master Business and Finance 4x Faster
                  </span>
                  <span className={styles.desc}>
                    AI simplifies complex concepts, tailors them to your
                    business
                  </span>
                  {/* <div className={styles.icon4xCont}>
                    <Icon4x />
                  </div> */}

                  <img
                    src={bootcampMainImage}
                    alt=""
                    className={styles.bootcampMainImage}
                  />

                  <div className={styles.learnMore}>
                    <button
                      className={styles.learnMorePink}
                      onClick={() => {
                        document.body.scrollTop = 0; // For Safari
                        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                        // Assuming 'navigate' is a function to navigate to a different page
                        navigate("/products/bootcamp");
                      }}
                    >
                      Learn More
                    </button>
                    <div className={styles.arrowIcon}>
                      <ArrowIcon />
                    </div>
                  </div>
                </div>
                {/* <div className={`${styles.victoryImgCont} `}>
                  <img
                    src={victory}
                    alt=""
                    className={`${styles.victoryImg} ${styles.cardWhite}`}
                  />
                  <VictoryCircularText />
                </div> */}

                {/* -------------------------victory---------------------------- */}

                <div className={`${styles.victoryImgCont}`}>
                  <img
                    src={victory}
                    alt=""
                    className={`${styles.victoryImg} ${styles.cardVictory}`}
                  />
                  <div className={`${styles.victoryCircleText}`}>
                    <VictoryCircularText />
                  </div>
                </div>
              </div>
              <div className={styles.weAscend1Top2}>
                <div className={styles.percentWsmesCont}>
                  <div
                    className={`${styles.percentWsmesCont1} ${styles.cardPink}`}
                  >
                    <div className={styles.percentCont}>
                      <span className={styles.percentText}>17%</span>
                      <span className={styles.percentText2}>
                        Aspiring women entrepreneurs
                      </span>
                    </div>
                    <div className={styles.percentCont}>
                      <span className={styles.percentText}>60%</span>
                      <span className={styles.percentText2}>
                        Stalled by self-doubt
                      </span>
                    </div>
                    <div className={styles.percentCont}>
                      <span className={styles.percentText}>74%</span>
                      <span className={styles.percentText2}>
                        WSMEs finance knowledge barrier
                      </span>
                    </div>
                    <div className={styles.percentCont}>
                      <span className={styles.percentText}>70%</span>
                      <span className={styles.percentText2}>
                        Unmet WSMEs financing needs
                      </span>
                    </div>
                  </div>

                  {/* ----------------------------------old card--------------- */}

                  {/* <div
                    className={`${styles.percentWsmesCont2} ${styles.cardWhite}`}
                  >
                    <span className={styles.title}>WSMEs</span>
                    <div className={styles.percentCont}>
                      <span className={styles.percentText}>74%</span>
                      <span className={styles.percentText2}>
                        Finance knowledge barrier
                      </span>
                    </div>
                    <div className={styles.percentCont}>
                      <span className={styles.percentText}>70%</span>
                      <span className={styles.percentText2}>
                        Unmet financing needs
                      </span>
                    </div>
                    <div className={styles.percentContTrillion}>
                      <span className={styles.percentTextTn1}>$1.4 Tn</span>
                      <span className={styles.percentText2}>
                        Annual credit gap
                      </span>
                    </div>
                  </div> */}

                  {/* --------------------new card--------------------------------- */}

                  <div
                    className={`${styles.percentWsmesCont2} ${styles.cardWhite}`}
                  >
                    <span className={styles.trillion}>$1.4 Tn</span>
                    <span className={styles.estimated}>
                      WSMEs Annual Credit Gap
                    </span>
                  </div>

                  {/* --------------card end------------------------ */}
                </div>

                <div className={`${styles.logoCont} ${styles.cardWhite}`}>
                  <div className={styles.ascendLogoWrap}>
                    {" "}
                    {/* <WeAscendLogoIcon /> */}
                    <img src={weAscendLogoImg} alt="" />
                  </div>
                </div>
              </div>
            </div>

            {/* ------------------------------------------------- */}
            <div className={styles.weAscend1Bottom}>
              <div className={`${styles.compass} ${styles.cardPink}`}>
                <div className={styles.compass1}>
                  <span
                    className={styles.titleWhite}
                    style={{ marginBottom: "0.8rem" }}
                  >
                    WeAscend Inner Compass
                  </span>
                  <span
                    className={styles.headlineWhite}
                    style={{ marginBottom: "2.4rem" }}
                  >
                    Unleash Your Confidence
                  </span>
                  <span
                    className={styles.descWhite}
                    style={{ marginBottom: "1.3rem" }}
                  >
                    Empower your awareness with self-leadership and psychometric
                    insights
                  </span>
                </div>

                <div className={`${styles.learnMoreCont} `}>
                  <div className={styles.learnMore}>
                    <button
                      className={styles.learnMoreWhite}
                      onClick={() => {
                        document.body.scrollTop = 0; // For Safari
                        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                        // Assuming 'navigate' is a function to navigate to a different page
                        navigate("/products/inner-compass");
                      }}
                    >
                      Learn More
                    </button>

                    <div className={styles.arrowIcon}>
                      <ArrowIconWhite />
                    </div>
                  </div>
                  {/* <GrowthIcon /> */}
                  {/* <img src={graphImg} alt="" style={{ width: "42%" }} /> */}
                  <img style={{ width: "35%" }} src={compassImg} alt="" />
                </div>
              </div>

              <div className={`${styles.circle} ${styles.cardWhite}`}>
                <div className={styles.circle1}>
                  {" "}
                  <span
                    className={styles.title}
                    style={{ marginBottom: "0.8rem" }}
                  >
                    WeAscend Circle
                  </span>
                  <span
                    className={styles.headline}
                    style={{ marginBottom: "2.4rem" }}
                  >
                    Connect, Celebrate, Succeed
                  </span>
                  <span
                    className={styles.desc}
                    style={{ marginBottom: "3.2rem" }}
                  >
                    Your tribe of inspiring female entrepreneurs awaits
                  </span>
                </div>

                <div className={styles.learnMoreCont}>
                  <div className={styles.learnMore}>
                    <button
                      className={styles.learnMorePink}
                      onClick={() => {
                        document.body.scrollTop = 0; // For Safari
                        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                        // Assuming 'navigate' is a function to navigate to a different page
                        navigate("/products/circle");
                      }}
                    >
                      Learn More
                    </button>
                    <div className={styles.arrowIcon}>
                      <ArrowIcon />
                    </div>
                  </div>
                  <img
                    src={womenInCirclesPlus}
                    alt=""
                    className={styles.womenInCirclesPlus}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.weAscend2}>
            <div className={`${styles.credit} ${styles.cardWhite}`}>
              <span className={styles.title} style={{ marginBottom: "1.6rem" }}>
                WeAscend Credit
              </span>
              <span
                className={styles.headline}
                style={{ marginBottom: "2.4rem" }}
              >
                Fast Track to Business Funding
              </span>
              <span className={styles.desc} style={{ marginBottom: "2.4rem" }}>
                Demystify loans, secure financing 2x faster with secure data
                sharing
              </span>

              <div
                className={styles.creditedByCont}
                style={{ marginBottom: "3.2rem" }}
              >
                <span className={styles.creditedByText}>
                  Your Account is credited by Rs 500,000
                </span>
              </div>

              {/* <div className={styles.lockProfileCont}>
                <img src={lock} alt="" className={styles.lockImg} />

                <div className={styles.ovalIconCont}>
                  <OvalIcon />
                </div>

                <img
                  src={creditProfile}
                  alt=""
                  className={styles.profilePicImg}
                />
              </div> */}

              {/* <div
                className={styles.lockProfileCont}
                style={{ marginBottom: "4rem" }}
              > */}
              <img
                src={lockProfileImg}
                alt=""
                className={styles.profilePicImg}
                style={{ marginBottom: "4rem" }}
              />
              {/* </div> */}

              <div className={styles.learnMore}>
                <button
                  className={styles.learnMorePink}
                  onClick={() => {
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                    // Assuming 'navigate' is a function to navigate to a different page
                    navigate("/products/credit");
                  }}
                >
                  Learn More
                </button>
                <div className={styles.arrowIcon}>
                  <ArrowIcon />
                </div>
              </div>
            </div>
            <div className={`${styles.amal} ${styles.cardPink}`}>
              <span
                className={styles.titleWhite}
                style={{ marginBottom: "0.6rem" }}
              >
                WeAscend AMAL Bot
              </span>
              <span
                className={styles.headlineWhite}
                style={{ marginBottom: "2.4rem" }}
              >
                Your AI Biz Bot Bestie
              </span>
              <span
                className={styles.descWhite}
                style={{ marginBottom: "4rem" }}
              >
                Advice, pep talks, venting, emotional support - 24/7
              </span>
              {/* <div className={styles.amalMainImageCont}> */}
              <img
                src={amalMainImage}
                alt=""
                className={styles.amalMainImage}
              />
              {/* </div> */}

              <div className={styles.learnMore}>
                <button
                  className={styles.learnMoreWhite}
                  onClick={() => {
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                    // Assuming 'navigate' is a function to navigate to a different page
                    navigate("/products/amal-bot");
                  }}
                >
                  Learn More
                </button>
                <div className={styles.arrowIcon}>
                  <ArrowIconWhite />
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <div className={styles.visiblityMobile}>
          <WeAscendMobileComp />
        </div>

        <div className={styles.empoweringCont}>
          <span className={styles.empHeading}>
            Empowering Every Woman Entrepreneur, <br /> at Every Step of Her
            Journey
          </span>
          <div className={styles.empCardsCont}>
            <div className={styles.empCardsContRow}>
              {/* ---------------------card 1------------------------- */}

              <div className={styles.empCardsContRow1}>
                <div>
                  <div className={styles.empIconCont}>
                    <BulbIcon />
                    <div className={styles.aspiringCont}>
                      <span className={styles.aspiringText}>
                        Aspiring Entrepreneurs
                      </span>
                      <span className={styles.dreamText}>
                        Dream Big, Start Strong
                      </span>
                    </div>
                  </div>
                  <span className={styles.transformText}>
                    Transform your innovating ideas into actionable plans with
                    fundamental business and financial skills
                  </span>
                </div>

                <div className={styles.learnMoreEmpCont}>
                  <button
                    className={styles.learnMoreEmp}
                    onClick={() => {
                      document.body.scrollTop = 0; // For Safari
                      document.documentElement.scrollTop = 0;
                      navigate("/aspiring-entrepreneurs");
                    }}
                  >
                    Learn More
                  </button>
                  <div className={styles.arrowIcon}>
                    <ArrowIcon />
                  </div>
                </div>
              </div>

              {/* ---------------------card 2------------------------- */}

              <div className={styles.empCardsContRow1}>
                <div>
                  <div className={styles.empIconCont}>
                    <SfIcon />
                    <div className={styles.aspiringCont}>
                      <span className={styles.aspiringText}>
                        Startup Founders
                      </span>
                      <span className={styles.dreamText}>
                        Build Your Brand, Grow Your Vision
                      </span>
                    </div>
                  </div>
                  <span className={styles.transformText}>
                    Navigate the early challenges of startup life, from brand
                    creation to emotional resilience, with AI-driven support
                  </span>
                </div>

                <div className={styles.learnMoreEmpCont}>
                  <button
                    className={styles.learnMoreEmp}
                    onClick={() => {
                      document.body.scrollTop = 0; // For Safari
                      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                      // Assuming 'navigate' is a function to navigate to a different page
                      navigate("/startup-founders");
                    }}
                  >
                    Learn More
                  </button>
                  <div className={styles.arrowIcon}>
                    <ArrowIcon />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.empCardsContRow}>
              {/* ---------------------card 3------------------------- */}

              <div className={styles.empCardsContRow1}>
                <div>
                  <div className={styles.empIconCont}>
                    <GrowthStageIcon />
                    <div className={styles.aspiringCont}>
                      <span className={styles.aspiringText}>
                        Growth Stage Entrepreneurs
                      </span>
                      <span className={styles.dreamText}>
                        Expand Your Reach, Elevate Your Impact
                      </span>
                    </div>
                  </div>
                  <span className={styles.transformText}>
                    Unlock your growth potential, refine your offerings, and
                    reach new markets with tailored strategies and resources
                  </span>
                </div>
                <div className={styles.learnMoreEmpCont}>
                  <button
                    className={styles.learnMoreEmp}
                    onClick={() => {
                      document.body.scrollTop = 0; // For Safari
                      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                      // Assuming 'navigate' is a function to navigate to a different page
                      navigate("/growth-stage");
                    }}
                  >
                    Learn More
                  </button>
                  <div className={styles.arrowIcon}>
                    <ArrowIcon />
                  </div>
                </div>
              </div>

              {/* ---------------------card 4------------------------- */}

              <div className={styles.empCardsContRow1}>
                <div>
                  <div className={styles.empIconCont}>
                    <EmerBizIcon />
                    <div className={styles.aspiringCont}>
                      <span className={styles.aspiringText}>
                        Emerging Business Leaders
                      </span>
                      <span className={styles.dreamText}>
                        Lead With Vision, Innovate With Power
                      </span>
                    </div>
                  </div>
                  <span className={styles.transformText}>
                    Access cutting-edge resources, fuel strategic growth, and
                    inspire the next generation of female leaders
                  </span>
                </div>

                <div className={styles.learnMoreEmpCont}>
                  <button
                    className={styles.learnMoreEmp}
                    onClick={() => {
                      document.body.scrollTop = 0; // For Safari
                      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                      // Assuming 'navigate' is a function to navigate to a different page
                      navigate("/emerging-leaders");
                    }}
                  >
                    Learn More
                  </button>
                  <div className={styles.arrowIcon}>
                    <ArrowIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.ourValuesCont}>
          <span className={styles.valuesText}>Our Values</span>

          <ValuesCards />

          {/* <div className={styles.valuesCardsCont}>
            <div className={styles.valuesCard1}>
              <div className={styles.barTextCont}>
                <div className={styles.bar1}></div>
                <span className={styles.barText}>
                  Empathy and Understanding
                </span>
              </div>
            </div>
            <div className={styles.valuesCard2}>
              <div className={styles.barTextCont}>
                <div className={styles.bar2}></div>
                <span className={styles.barText}>
                  Ethical Innovation and Transparency
                </span>
              </div>
            </div>
            <div className={styles.valuesCard3}>
              <div className={styles.barTextCont}>
                <div className={styles.bar3}></div>
                <span className={styles.barText}>
                  Empowerment and Knowledge
                </span>
              </div>
            </div>
            <div className={styles.valuesCard4}>
              <div className={styles.barTextCont}>
                <div className={styles.bar4}></div>
                <span className={styles.barText}>
                  Audacious Ambition and Support
                </span>
              </div>
            </div>
          </div> */}
        </div>

        {/* ---------------------------------------------------REVIEWS------------------------------- */}

        <div className={styles.reviews}>
          <div className={styles.imgHeadingCont}>
            <div className={styles.quoteImgCont}>
              <img className={styles.image} src={quotes} alt="" />
            </div>
            <div className={styles.cusTextCont}>
              {" "}
              <span className={styles.cusText}>Customer Reviews</span>
            </div>
          </div>

          <div className={styles.reviewsCardsCont}>
            <div className={styles.reviewsCard1}>
              <div className={styles.reviewsCard1cards}>
                <div className={styles.reviewsCard11}>
                  <div className={styles.reviewsProfileCont}>
                    <div className={styles.reviewsDpCont}>
                      <img
                        className={styles.image}
                        src={reviewsprofile1}
                        alt=""
                      />
                    </div>

                    <div className={styles.nameDesCont}>
                      <span className={styles.name}>Sahar Arshad</span>
                      <span className={styles.desig}>Tailor Made Tales</span>
                    </div>
                  </div>

                  <p className={styles.descWhite}>
                    WeAscend Inner Compass assessment was an excellent
                    experience. It pinpointed the exact areas I needed to
                    improve on, providing a clear path for personal and
                    professional growth. This program has been instrumental in
                    shaping my journey towards becoming a more effective
                    leader.&quot;
                  </p>
                </div>
                <div className={styles.reviewsCard12}>
                  <div className={styles.reviewsProfileCont}>
                    <div className={styles.reviewsDpCont}>
                      <img
                        className={styles.image}
                        src={reviewsprofile2}
                        alt=""
                      />
                    </div>

                    <div className={styles.nameDesCont}>
                      <span className={styles.name}>Hirra Tanveer</span>
                      <span className={styles.desig}>GenWeft</span>
                    </div>
                  </div>

                  <p className={styles.descWhite}>
                    AMAL has been a cornerstone of emotional support for me. In
                    the rollercoaster journey of entrepreneurship, AMAL provided
                    not just advice, but also a listening ear for venting and
                    invaluable pep talks&quot;
                  </p>
                </div>
              </div>

              {/* ------------- ----------------------------------*/}

              <div className={styles.reviewsCard1cards}>
                <div className={styles.reviewsCard13}>
                  <div className={styles.reviewsProfileCont}>
                    <div className={styles.reviewsDpCont}>
                      <img
                        className={styles.image}
                        src={reviewsprofile3}
                        alt=""
                      />
                    </div>

                    <div className={styles.nameDesCont}>
                      <span className={styles.name}>Nikhar Azhar</span>
                      <span className={styles.desig}>MeyaBake</span>
                    </div>
                  </div>

                  <p className={styles.descWhite}>
                    The WeAscend Inner Compass assessment was a game-changer for
                    me. It offered profound insights into self-leadership,
                    giving me with the confidence I needed to navigate my path.
                    Every session felt like a step closer to realizing my true
                    potential.
                  </p>
                </div>
                <div className={styles.reviewsCard14}>
                  <div className={styles.reviewsProfileCont}>
                    <div className={styles.reviewsDpCont}>
                      <img
                        className={styles.image}
                        src={reviewsprofile4}
                        alt=""
                      />
                    </div>

                    <div className={styles.nameDesCont}>
                      <span className={styles.name}>Ayesha Wajahat</span>
                      <span className={styles.desig}>
                        Online Psychological Help
                      </span>
                    </div>
                  </div>

                  <p className={styles.descWhite}>
                    The design of WeAscend Bootcamp is impeccable. It concisely
                    encapsulated what I learned in a 4-day workshop on social
                    entrepreneurship, providing a rich, streamlined experience.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.reviewsCard2}>
              <div className={styles.reviewsCard21}>
                <div className={styles.reviewsProfileCont}>
                  <div className={styles.reviewsDpCont}>
                    <img
                      className={styles.image}
                      src={reviewsprofile5}
                      alt=""
                    />
                  </div>

                  <div className={styles.nameDesCont}>
                    <span className={styles.name}>Huma Ameer</span>
                    <span className={styles.desig}>BeyondWords</span>
                  </div>
                </div>

                <p className={styles.descWhite}>
                  Participating in the WeAscend Bootcamp was an unparalleled
                  experience; the course was thorough yet extremely engaging.
                  Despite its length, it offered a clear and relatable journey
                  through the entire business cycle, allowing me to learn
                  immensely in a short span. Truly the best course experience
                  I&#39;ve ever had!&quot;
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* -------------------------------------------------------Join The Aawaz Community------------------ */}

        {/* <div className={styles.joinCont}>
          <span className={styles.joinText}>Join The Aawaz Community</span>
          <p className={styles.joinPara}>
            Stay in the loop with exclusive updates, events, and offers by
            subscribing today
          </p>
          <div className={styles.emailSubCont}>
            <div className={styles.emailSub1}>
              <input
                type="email"
                placeholder="Enter your email"
                className={styles.input}
              />
            </div>

            <div className={styles.emailSub2}>
              <span className={styles.subBtn}>Subscribe</span>
            </div>
          </div>
        </div> */}

        {/* ---------------------------------------------FOOTER----------------------------- */}

        <Footer />
      </div>
    </div>
  );
};

export default Home;
