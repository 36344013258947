import React, { useState } from "react";
import styles from "./FooterB.module.css";
import LogoIcon from "../../assets/icons/LogoIcon";
import FbIcon from "../../assets/icons/FbIcon";
import TwitterIcon from "../../assets/icons/TwitterBlack";
import InstaIcon from "../../assets/icons/InstaWhite";
import aawazWhiteLogo from "../../assets/images/aawazWhiteLogo.png";
import OvalSmIcon from "../../assets/icons/OvalSmIcon";
import { useNavigate } from "react-router-dom";

const FooterB = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.footerCont}>
        <div className={styles.menusSec}>
          <div className={styles.menuSec}>
            <div className={styles.menuSecInner}>
              <span className={styles.menuHeading}>Products</span>
              <a
                onClick={() => {
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                  // Assuming 'navigate' is a function to navigate to a different page
                  navigate("/products/bootcamp");
                }}
              >
                Bootcamp
              </a>
              <a
                onClick={() => {
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                  // Assuming 'navigate' is a function to navigate to a different page
                  navigate("/products/inner-compass");
                }}
              >
                Inner Compass
              </a>
              <a
                onClick={() => {
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                  // Assuming 'navigate' is a function to navigate to a different page
                  navigate("/products/amal-bot");
                }}
              >
                AMAL Bot
              </a>
              <a
                onClick={() => {
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                  // Assuming 'navigate' is a function to navigate to a different page
                  navigate("/products/circle");
                }}
              >
                Circle
              </a>
              <a
                onClick={() => {
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                  // Assuming 'navigate' is a function to navigate to a different page
                  navigate("/products/credit");
                }}
              >
                Credit
              </a>
            </div>
          </div>
          <div className={`${styles.menuSec} `}>
            <div className={styles.menuSecInner}>
              <span className={styles.menuHeading}>Users</span>
              <a
                onClick={() => {
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                  // Assuming 'navigate' is a function to navigate to a different page
                  navigate("/users/aspiring-entrepreneurs");
                }}
              >
                Aspiring
              </a>
              <a
                onClick={() => {
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                  // Assuming 'navigate' is a function to navigate to a different page
                  navigate("/users/startup-founders");
                }}
              >
                Startup Founders
              </a>
              <a
                onClick={() => {
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                  // Assuming 'navigate' is a function to navigate to a different page
                  navigate("/users/growth-stage-entrepreneurs");
                }}
              >
                Growth Stage
              </a>
              <a
                onClick={() => {
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                  // Assuming 'navigate' is a function to navigate to a different page
                  navigate("/users/emerging-business-leaders");
                }}
              >
                Emerging Leaders
              </a>
            </div>
          </div>
          <div className={`${styles.menuSec} `}>
            <div className={styles.menuSecInner}>
              <a
                onClick={() => {
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                  // Assuming 'navigate' is a function to navigate to a different page
                  navigate("/financial-institutions");
                }}
                className={styles.menuHeading}
              >
                Financial Institutions
              </a>
            </div>
          </div>

          <div className={`${styles.menuSec} `}>
            <div className={styles.menuSecInner}>
              <span className={styles.menuHeading}>About Us</span>
              <a
                onClick={() => {
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
                  // Assuming 'navigate' is a function to navigate to a different page
                  navigate("/about-us/our-values");
                }}
              >
                Our Values
              </a>
            </div>
          </div>
        </div>

        <div className={styles.footerBottomSec}>
          <img className={styles.aawazWhiteLogo} src={aawazWhiteLogo} alt="" />
          <div className={styles.smIconsCont}>
            <FbIcon />
            <TwitterIcon />
            <InstaIcon />
            <OvalSmIcon />
          </div>
          <p className={styles.copyrights}>
            © 2024 Aawaz AI. All rights reserved
          </p>
        </div>
      </div>
    </>
  );
};

export default FooterB;
