import React from "react";

const OvalSmIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.4093 14.4696C16.4624 15.0259 15.9978 15.4843 15.428 15.4843H9.74685C9.20352 15.4843 8.76708 15.061 8.7103 14.5313C8.49282 12.5023 7.51457 7.93911 3.11436 7.93911C2.796 7.93911 2.3296 7.89958 1.92777 7.8581C1.45548 7.80934 1.10504 7.42924 1.08547 6.9615L0.8622 1.62551C0.837435 1.03363 1.34341 0.536753 1.94393 0.574259C2.85184 0.630964 4.10233 0.728258 4.89147 0.869332C14.459 2.5797 16.1207 11.4426 16.4093 14.4696Z"
        fill="white"
      />
    </svg>
  );
};

export default OvalSmIcon;
