import React, { useState } from "react";
import styles from "./FinancialInstitutions.module.css";
import Navbar from "../../components/navbar/Navbar";
import fi1img from "../../assets/images/fi1img.png";
import fi2img from "../../assets/images/fi2img.png";
import fi3img from "../../assets/images/fi3img.png";
import fi4img from "../../assets/images/fi4img.png";
import twitterBlack from "../../assets/images/twitterBlack.png";
import instaBlack from "../../assets/images/instaBlack.png";
import discordBlack from "../../assets/images/discordBlack.png";
import weAscendLogoW from "../../assets/images/weAscendLogoW.png";
import backArrow from "../../assets/images/backArrow.png";
import courseComp from "../../assets/images/courseComp.png";
import innerCompassImg from "../../assets/images/innerCompassImg.png";
import amalBotImg from "../../assets/images/amalBotImg.png";
import circleImg from "../../assets/images/circleImg.png";
import Footer from "../../components/footer/Footer";
import JoinBtn from "../../components/joinBtn/JoinBtn";
import PhoneIcon from "../../assets/icons/PhoneIcon";
import MailIcon from "../../assets/icons/MailIcon";
import LocationIcon from "../../assets/icons/LocationIcon";
import TwitterIcon from "../../assets/icons/TwitterBlack";
import InstaIcon from "../../assets/icons/InstaWhite";
import Twitter2Icon from "../../assets/icons/Twitter2Icon";
import Insta2Icon from "../../assets/icons/Insta2Icon";
import DiscordIcon from "../../assets/icons/DiscordBlack";
import InstaBlack from "../../assets/icons/InstaBlack";
import DiscordWhite from "../../assets/icons/DiscordWhite";
import TwitterWhite from "../../assets/icons/TwitterWhite";
import TwitterBlack from "../../assets/icons/TwitterBlack";
import DiscordBlack from "../../assets/icons/DiscordBlack";
import InstaWhite from "../../assets/icons/InstaWhite";

import Modal from "react-modal";
import close from "../../assets/images/close.png";
import subImg from "../../assets/images/subImg.png";

const FinancialInstitutions = ({ isScrolledToDiv }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [hoveredIcon, setHoveredIcon] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    designation: "",
    companyName: "",
    message: "",
  });

  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  console.log("hoveredIcon", hoveredIcon);

  // Function to handle input changes and update state
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Validate input for phone number: allow only digits and ensure it starts with '+'
    if (name === "phoneNumber") {
      if (/^\+?\d*$/.test(value)) {
        // Check if value contains only digits and optionally starts with '+'
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(
        `${apiBaseURL}/Subscribers/connection/postConnection`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            firstName: formData.firstName,
            lastName: formData.lastName,
            connectionEmail: formData.email,
            phone: formData.phoneNumber,
            designation: formData.designation,
            company: formData.companyName,
            message: formData.message,
          }),
        }
      );

      if (response?.ok) {
        // alert("The message has been sent successfully");
        setShowModal(true);

        console.log("Connection request sent successfully.");
        // Additional logic if needed
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          designation: "",
          companyName: "",
          message: "",
        });
      } else {
        console.error("Failed to send connection request:", response?.message);
      }
    } catch (error) {
      console.error(
        "Error occurred while sending connection request:",
        error?.message
      );
      alert(error?.message);
    } finally {
      setLoading(false); // Reset loading state regardless of success or failure
    }
  };

  return (
    <>
      <Navbar isScrolledToDiv={isScrolledToDiv} />
      <div className={styles.mainCont}>
        <div className={styles.sec1}>
          <div className={styles.sec1a}>
            <span className={styles.aspiring}>Financial Institutions</span>
            <span className={styles.headline}>
              Elevate Your Lending Impact with Aawaz AI Credit Scores
            </span>

            <div className={styles.ascendBtnWrap}>
              <div className={styles.ascendBtnWrap}>
                <JoinBtn />
              </div>
            </div>
          </div>
          <div className={styles.sec1b}>
            <img src={fi1img} alt="" style={{ width: "100%" }} />
          </div>
        </div>

        {/* -------------------------------------------------------------- */}

        <div className={styles.unlockParaCont}>
          <p className={styles.unlockParaText}>
            Access capital more easily with WeAscend Credit, providing strategic
            financial solutions designed with women entrepreneurs in mind.
            Understand the financial landscape, assess your creditworthiness,
            and find the best financing options for your business.
          </p>
        </div>

        {/* ------------------------------sec2------------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec2a}>
            <span className={styles.title}>Why Partner With Aawaz AI?</span>
            <span className={styles.headline}>
              <strong>Bridge the Credit Gap:</strong> Directly address the $1.5
              trillion global credit gap affecting women-owned businesses.
            </span>
            <span className={styles.headline}>
              <strong>Innovative Scoring:</strong>  Utilize alternative data,
              including transaction data and psychometrics, for a holistic view
              of creditworthiness.
            </span>
            <span className={styles.headline}>
              <strong>Expand Your Portfolio:</strong> Access an emerging market
              of resilient and innovative female entrepreneurs, enhancing
              portfolio diversity and sustainability..
            </span>
          </div>

          <div className={styles.sec2b}>
            <img src={fi2img} alt="" style={{ width: "100%" }} />
          </div>
        </div>

        {/* -----------------------------sec3-------------------------- */}

        <div className={styles.sec2}>
          <div className={styles.sec3b}>
            <img src={fi3img} alt="" style={{ width: "100%" }} />
          </div>
          <div className={styles.sec2a}>
            <span className={styles.title}>
              Key Advantages of Aawaz AI Credit Scores
            </span>
            <span className={styles.headline}>
              <strong>Comprehensive Credit Assessment:</strong> Alternative data
              provides a fuller picture of financial health beyond traditional
              metrics.
            </span>
            <span className={styles.headline}>
              <strong>Empowerment Through Access:</strong> Enable financial
              products, like business credit cards, for ventures traditionally
              overlooked by conventional systems.
            </span>
            <span className={styles.headline}>
              <strong>Secure and Consensual Data Sharing:</strong> Uphold
              privacy and trust through secure data exchanges, with explicit
              consent from WeAscend users.
            </span>
            <span className={styles.headline}>
              <strong>Efficient Decision-Making:</strong> Streamlined credit
              decisions with the ability to monitor financial health, predicting
              and mitigating risks early.
            </span>
            <span className={styles.headline}>
              <strong>Inclusive Financial Growth:</strong> Tailored for
              businesses with minimal lending history, opening doors to formal
              credit and financial growth.
            </span>
          </div>
        </div>

        {/* ----------------------------------------------------------------------------------- */}

        <div className={styles.howItWorksCont}>
          <span className={styles.worksText}>How It Works</span>
          <div className={styles.howItWorksCardsCont}>
            <div className={styles.howItWorksCard}>
              <div>
                {" "}
                <span className={styles.orangeText}>1</span>
              </div>
              <span className={styles.worksTitle}>Opt-in for Data Sharing</span>
              <span className={styles.worksDesc}>
                Female entrepreneurs chooseto securely share data through the
                WeAscend platform.
              </span>
            </div>
            <div className={styles.howItWorksCard}>
              <div>
                {" "}
                <span className={styles.orangeText}>2</span>
              </div>
              <span className={styles.worksTitle}>AI-Powered Analysis</span>
              <span className={styles.worksDesc}>
                Aawaz AI's advanced analytics and AI models assess the data.
              </span>
            </div>
            <div className={styles.howItWorksCard}>
              <div>
                {" "}
                <span className={styles.orangeText}>3</span>
              </div>
              <span className={styles.worksTitle}>
                Informed Lending Decisions
              </span>
              <span className={styles.worksDesc}>
                Financial institutions use the credit score alongside other
                factors to make informed lending decisions.
              </span>
            </div>
          </div>
        </div>

        <div className={styles.sec2}>
          <div className={styles.sec2a}>
            <span className={styles.title}>Why Partner With Aawaz AI?</span>
            <span className={styles.headline}>
              <strong>Bridge the Credit Gap:</strong> Directly address the $1.5
              trillion global credit gap affecting women-owned businesses.
            </span>
            <span className={styles.headline}>
              <strong>Innovative Scoring:</strong>  Utilize alternative data,
              including transaction data and psychometrics, for a holistic view
              of creditworthiness.
            </span>
            <span className={styles.headline}>
              <strong>Expand Your Portfolio:</strong> Access an emerging market
              of resilient and innovative female entrepreneurs, enhancing
              portfolio diversity and sustainability..
            </span>
          </div>

          <div className={styles.sec2b}>
            <img src={fi4img} alt="" style={{ width: "100%" }} />
          </div>
        </div>

        {/* ------------------------------------------------------------------------------------ */}

        <div className={styles.touchMainCont}>
          <span className={styles.touchText}>Get in touch</span>
          <div className={styles.contactCont}>
            <div className={styles.contactCont1}>
              <span className={styles.contactText}>Contact Information</span>
              <span className={styles.sayText}>
                Say something to start a live chat!
              </span>

              <div className={styles.infoCont}>
                <div className={styles.iconTextCont}>
                  <PhoneIcon />
                  <span>+92 322 5555451</span>
                </div>
                <div className={styles.iconTextCont}>
                  <MailIcon />
                  <span>seemab@aawaz.com.pk</span>
                </div>
                <div className={styles.iconTextCont}>
                  <div className={styles.locCont}>
                    <LocationIcon />
                  </div>
                  <span>
                    1203, 2nd Floor, NSTP Towers, NUST University, H-12,
                    Islamabad, Pakistan
                  </span>
                </div>
              </div>
              <div className={styles.smIconsWrap}>
                <div className={styles.smIconsCont}>
                  {/* <img src={twitterBlack} alt="" />
                  <img src={instaBlack} alt="" />
                  <img src={discordBlack} alt="" /> */}

                  {/* Show Twitter2Icon when hovered over */}
                  {hoveredIcon === "twitter" ? (
                    <div onMouseLeave={() => setHoveredIcon(null)}>
                      <TwitterWhite />
                    </div>
                  ) : (
                    <div onMouseEnter={() => setHoveredIcon("twitter")}>
                      <TwitterBlack />
                    </div>
                  )}

                  {/* Instagram Icons */}
                  {hoveredIcon === "insta" ? (
                    <div onMouseLeave={() => setHoveredIcon(null)}>
                      <InstaWhite />
                    </div>
                  ) : (
                    <div onMouseEnter={() => setHoveredIcon("insta")}>
                      <InstaBlack />
                    </div>
                  )}

                  {/* Discord Icons */}
                  {hoveredIcon === "discord" ? (
                    <div onMouseLeave={() => setHoveredIcon(null)}>
                      <DiscordWhite />
                    </div>
                  ) : (
                    <div onMouseEnter={() => setHoveredIcon("discord")}>
                      <DiscordBlack />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <form className={styles.contactCont2} onSubmit={handleSubmit}>
              <div className={styles.flexRow}>
                <div className={styles.labelInputCont}>
                  <label className={styles.label} htmlFor="firstName">
                    First Name
                  </label>
                  <input
                    // placeholder="John"
                    className={styles.input}
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={styles.labelInputCont}>
                  <label className={styles.label} htmlFor="lastName">
                    Last Name
                  </label>
                  <input
                    // placeholder="Doe"
                    className={styles.input}
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className={styles.flexRow}>
                <div className={styles.labelInputCont}>
                  <label className={styles.label} htmlFor="email">
                    Email *
                  </label>
                  <input
                    // placeholder="example@gmail.com"
                    className={styles.input}
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className={styles.labelInputCont}>
                  <label className={styles.label} htmlFor="phoneNumber">
                    Phone Number
                  </label>
                  <input
                    placeholder="+"
                    className={styles.input}
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className={styles.flexRow}>
                <div className={styles.labelInputCont}>
                  <label className={styles.label} htmlFor="designation">
                    Designation
                  </label>
                  <input
                    className={styles.input}
                    type="text"
                    id="designation"
                    name="designation"
                    value={formData.designation}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={styles.labelInputCont}>
                  <label className={styles.label} htmlFor="companyName">
                    Company Name
                  </label>
                  <input
                    // placeholder="ABC limited"
                    className={styles.input}
                    type="text"
                    id="companyName"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className={styles.areaCont}>
                <label className={styles.label} htmlFor="message">
                  Message
                </label>
                <textarea
                  className={styles.area}
                  placeholder="Write your message.."
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  rows={10} // Set the number of visible text lines
                  // cols={50} // Set the visible width of the text area
                />
              </div>
              <div className={styles.sendBtnCont}>
                {" "}
                {loading ? (
                  <>
                    <div className={styles.loadCont}>
                      {" "}
                      <div className={styles.loader}></div>
                    </div>
                  </>
                ) : (
                  <button type="submit" className={styles.sendBtn}>
                    Send Message
                  </button>
                )}
              </div>
              {/* {loading && <div className={styles.loader}></div>} */}
            </form>

            <Modal
              isOpen={showModal}
              onRequestClose={() => setShowModal(false)}
              contentLabel="Correct Input Data Modal"
              className={styles.modal}
            >
              <div className={styles.modalCont}>
                <div
                  className={styles.crossIconCont}
                  onClick={() => setShowModal(false)}
                >
                  <img src={close} alt="" />
                </div>
                <div className={styles.sureCont}>
                  <div className={styles.sureTextCont}>
                    <p>Success!</p>
                  </div>
                </div>
                <div className={styles.subImgCont}>
                  {" "}
                  <img src={subImg} alt="" />
                </div>
                <p className={styles.text}>Message sent successfully</p>
                {/* Add any additional actions or content needed in the modal */}
              </div>
            </Modal>
          </div>
        </div>

        {/* -----------------------------footer--------------------------- */}
      </div>
      <Footer />
    </>
  );
};

export default FinancialInstitutions;
