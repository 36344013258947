import React from "react";
import styles from "./ImageSlider.module.css"; // Import your CSS module
import adb from "../../assets/images/adb.png";
import wfx from "../../assets/images/wfx.png";
import icesco from "../../assets/images/icesco.png";
import bankIslami from "../../assets/images/bankIslami.png";

const ImageSlider = () => {
  return (
    <div className={styles.imgsCont}>
      <div className={styles.slider}>
        <div className={styles.imgCont}>
          <img src={adb} alt="" className={styles.bankImage1} />
        </div>
        <div className={styles.imgCont}>
          <img src={wfx} alt="" className={styles.bankImage2} />
        </div>
        <div className={styles.imgCont}>
          <img src={icesco} alt="" className={styles.bankImage3} />
        </div>

        <div className={styles.imgCont}>
          <img src={bankIslami} alt="" className={styles.bankImage4} />
        </div>
      </div>
    </div>
  );
};

export default ImageSlider;
