import React from "react";

const GrowthStageIcon = () => {
  return (
    <svg
      // width="596"
      height="53"
      viewBox="0 0 596 597"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M417.335 1.55807C404.802 7.02475 400.802 24.0914 409.602 34.4914C415.735 41.8248 418.802 42.3581 452.268 42.3581L482.668 42.4914L459.335 65.4248C361.202 161.958 239.868 231.691 100.802 271.691C72.8016 279.825 69.2016 281.558 65.2016 289.291C62.8016 293.958 62.8016 302.758 65.335 307.558C68.135 313.025 74.6683 318.091 80.4016 319.158C86.2683 320.358 98.135 317.425 132.668 306.891C180.802 292.091 222.002 275.558 268.668 252.225C353.468 209.958 423.602 160.091 493.068 93.0248L511.202 75.5581L511.602 105.558C512.002 133.425 512.135 135.825 514.802 139.158C520.002 146.225 524.268 148.358 532.668 148.358C541.068 148.358 545.335 146.225 550.535 139.158C553.335 135.558 553.335 134.091 553.335 74.3581C553.335 14.6248 553.335 13.1581 550.535 9.55807C549.068 7.55807 546.135 4.62476 544.135 3.15808C540.535 0.491425 538.802 0.358093 480.935 0.0914307C433.468 -0.175232 420.535 0.0914307 417.335 1.55807Z"
        fill="#FF0071"
      />
      <path
        d="M458.668 235.558C446.535 238.625 435.601 247.558 430.001 259.025L426.668 265.691V415.691V565.691L429.735 571.691C434.401 581.291 439.735 586.891 448.935 591.825L457.335 596.358H511.335H565.335L572.135 592.758C582.268 587.425 586.401 583.425 591.468 574.091L596.001 565.691V415.691V265.691L591.468 257.291C586.535 248.091 580.935 242.758 571.335 238.091C565.468 235.025 564.401 235.025 514.001 234.758C485.735 234.625 460.935 235.025 458.668 235.558ZM554.001 415.691V554.358H511.335H468.668V415.691V277.025H511.335H554.001V415.691Z"
        fill="#FF0071"
      />
      <path
        d="M245.332 342.225C233.199 345.291 222.265 354.225 216.665 365.691L213.332 372.358V469.025V565.691L216.399 571.691C221.065 581.291 226.399 586.891 235.599 591.825L243.999 596.358H297.999H351.999L360.399 591.825C369.732 586.758 373.732 582.625 379.065 572.491L382.665 565.691V469.025V372.358L378.132 363.958C373.199 354.758 367.599 349.425 357.999 344.758C352.132 341.691 351.065 341.691 300.665 341.425C272.399 341.291 247.599 341.691 245.332 342.225ZM340.665 469.025V554.358H297.999H255.332V469.025V383.691H297.999H340.665V469.025Z"
        fill="#FF0071"
      />
      <path
        d="M32 406.225C19.8667 409.291 8.93333 418.225 3.33333 429.691L0 436.358V501.025C0 565.425 0 565.691 3.06667 571.691C7.73333 581.291 13.0667 586.891 22.2667 591.825L30.6667 596.358H84.6667H138.667L145.467 592.758C155.6 587.425 159.733 583.425 164.8 574.091L169.333 565.691V501.025V436.358L164.8 427.958C159.867 418.758 154.267 413.425 144.667 408.758C138.8 405.691 137.733 405.691 87.3333 405.425C59.0667 405.291 34.2667 405.691 32 406.225ZM127.333 501.025V554.358H84.6667H42V501.025V447.691H84.6667H127.333V501.025Z"
        fill="#FF0071"
      />
    </svg>
  );
};

export default GrowthStageIcon;
