import React from "react";

const FbIcon = () => {
  return (
    <svg
      width="10"
      height="18"
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.82014 8.59472H6.23518C6.23518 12.3878 6.23518 17.0568 6.23518 17.0568H2.40478C2.40478 17.0568 2.40478 12.4331 2.40478 8.59472H0.583984V5.60396H2.40478V3.66949C2.40478 2.28403 3.12158 0.119141 6.27038 0.119141L9.10878 0.129133V3.03231C9.10878 3.03231 7.38398 3.03231 7.04862 3.03231C6.71326 3.03231 6.23646 3.18632 6.23646 3.84701V5.60455H9.15486L8.82014 8.59472Z"
        fill="white"
      />
    </svg>
  );
};

export default FbIcon;
