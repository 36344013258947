import React from "react";
import styles from "./Chatbot.module.css";
import ChatbotComp from "../../components/chatbotComp/ChatbotComp";
import NavbarChatbot from "../../components/navbarChatbot/NavbarChatbot";
import CbIcon1 from "../../assets/icons/CbIcon1";
import CbIcon2 from "../../assets/icons/CbIcon2";
import CbIcon3 from "../../assets/icons/CbIcon3";
import CbIcon4 from "../../assets/icons/CbIcon4";
import CbIcon5 from "../../assets/icons/CbIcon5";

const Chatbot = () => {
  return (
    <div className={styles.mainCont}>
      <div className={styles.sec1}>
        <div className={styles.iconsCont}>
          <div className={styles.roundedDivInac}>
            <CbIcon1 />
          </div>
          <div className={styles.roundedDivInac}>
            <CbIcon2 />
          </div>
          <div className={styles.roundedDivAct}>
            <CbIcon3 />
          </div>
          <div className={styles.roundedDivInac}>
            <CbIcon4 />
          </div>
          <div className={styles.roundedDivInac}>
            <CbIcon5 />
          </div>
        </div>
      </div>
      <div className={styles.sec2}>
        <NavbarChatbot />

        <div className={styles.chatbotCont}>
          <ChatbotComp />
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
