import React from "react";
import styles from "./ChatbotComp.module.css"; // Import CSS Module
import Navbar from "../navbar/Navbar";

const ChatbotComp = () => {
  return (
    <div className={styles.chatbotContainer}>
      <iframe
        title="Chatbot"
        src="https://19798120121317.com/"
        width="100%"
        height="600px"
        className={styles.chatbotIframe}
      ></iframe>
    </div>
  );
};

export default ChatbotComp;
