import React from "react";

const VictoryCircularText = () => {
  return (
    <svg
      width="75"
      height="79"
      viewBox="0 0 75 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.3159 75.3335L36.804 75.1605L34.8474 68.4339L34.7506 68.4229L31.4495 74.5478L29.9375 74.3747L30.8024 66.1085L31.9878 66.2441L31.3619 72.2259L31.4385 72.2346L34.4984 66.5437L35.4822 66.6563L37.2925 72.9086L37.3691 72.9173L37.9954 66.9316L39.1808 67.0672L38.3159 75.3335Z"
        fill="white"
      />
      <path
        d="M24.9277 63.3794L26.0117 64.1519L19.0388 69.3799L17.8589 68.5392L19.9602 59.8397L21.0443 60.6122L19.2449 67.6746L19.2978 67.7123L24.9277 63.3794ZM22.9692 65.9769L19.4526 63.4711L20.0286 62.587L23.5452 65.0929L22.9692 65.9769Z"
        fill="white"
      />
      <path
        d="M15.5118 53.6989L16.8325 56.0431L9.75674 60.4033L8.39414 57.9849C7.99439 57.2754 7.79274 56.5788 7.78919 55.8951C7.78333 55.2128 7.96776 54.5736 8.3425 53.9775C8.7136 53.3805 9.26653 52.8555 10.0013 52.4028C10.7383 51.9486 11.4495 51.6961 12.1346 51.6455C12.8184 51.5925 13.4492 51.7372 14.027 52.0798C14.6025 52.4238 15.0974 52.9635 15.5118 53.6989ZM15.2841 55.5254L14.6128 54.3339C14.302 53.7823 13.9424 53.3863 13.5338 53.1458C13.123 52.9068 12.6729 52.8158 12.1837 52.8727C11.6922 52.9311 11.1666 53.1327 10.6069 53.4776C10.0518 53.8197 9.63189 54.2007 9.34719 54.6206C9.06117 55.0382 8.91823 55.4867 8.91838 55.9662C8.91853 56.4456 9.06735 56.9493 9.36484 57.4773L10.074 58.736L15.2841 55.5254Z"
        fill="white"
      />
      <path
        d="M12.1583 45.2324L3.90873 46.2439L3.32822 41.0655L4.3997 40.9341L4.84051 44.8663L7.35405 44.5581L6.94354 40.8962L8.01099 40.7653L8.4215 44.4273L10.9471 44.1176L10.5009 40.137L11.5724 40.0056L12.1583 45.2324Z"
        fill="white"
      />
      <path
        d="M13.6145 28.3046L6.48012 24.0409L8.95152 19.518L9.87816 20.0718L8.00806 23.4942L10.1818 24.7933L11.8748 21.695L12.798 22.2467L11.105 25.345L14.2158 27.2041L13.6145 28.3046Z"
        fill="white"
      />
      <path
        d="M22.6299 11.6146C23.1328 12.3457 23.4355 13.0719 23.5379 13.7932C23.6389 14.5122 23.5608 15.1832 23.3036 15.8063C23.0472 16.4254 22.6325 16.9506 22.0596 17.3816C21.4845 17.8143 20.8744 18.0602 20.2294 18.1193C19.5849 18.1745 18.9521 18.0367 18.3307 17.7058C17.7094 17.375 17.1481 16.8451 16.6467 16.1162C16.1437 15.3851 15.8418 14.66 15.7409 13.941C15.6384 13.2198 15.7154 12.5495 15.9719 11.9304C16.229 11.3073 16.6451 10.7795 17.2202 10.3468C17.7931 9.91573 18.4018 9.67259 19.0462 9.61734C19.6913 9.55824 20.3245 9.69412 20.9458 10.025C21.5655 10.3536 22.1269 10.8835 22.6299 11.6146ZM21.6375 12.3612C21.2542 11.804 20.8438 11.4006 20.4065 11.1512C19.9698 10.898 19.5327 10.7871 19.0951 10.8187C18.6581 10.8464 18.2493 11.0034 17.8688 11.2897C17.4862 11.5776 17.2128 11.9338 17.0488 12.3582C16.8832 12.7805 16.8447 13.2492 16.9333 13.7643C17.0226 14.2756 17.2589 14.8099 17.6422 15.3672C18.0256 15.9244 18.4356 16.3297 18.8723 16.583C19.3096 16.8324 19.7468 16.9432 20.1838 16.9155C20.6192 16.8856 21.0283 16.7266 21.4109 16.4387C21.7914 16.1524 22.0645 15.7982 22.2301 15.3759C22.3963 14.9498 22.4347 14.4811 22.3455 13.9698C22.2569 13.4547 22.0209 12.9185 21.6375 12.3612Z"
        fill="white"
      />
      <path
        d="M29.6868 12.8855L28.2351 4.70192L31.1435 4.13769C31.7756 4.01506 32.3195 4.02251 32.7753 4.16005C33.2336 4.29708 33.6007 4.53863 33.8764 4.88471C34.1516 5.22813 34.3339 5.65158 34.4232 6.15506C34.512 6.65588 34.4853 7.11712 34.343 7.53877C34.203 7.95724 33.9418 8.3124 33.5595 8.60427C33.1798 8.89562 32.6739 9.10261 32.0418 9.22525L29.8386 9.65267L29.6501 8.58976L31.7417 8.18398C32.1401 8.10669 32.454 7.98655 32.6833 7.82356C32.9153 7.66006 33.0704 7.45776 33.1485 7.21664C33.2266 6.97553 33.2385 6.70179 33.1841 6.39544C33.1293 6.08642 33.0221 5.83029 32.8624 5.62704C32.7054 5.42328 32.4901 5.28594 32.2165 5.21501C31.9451 5.14091 31.6062 5.14327 31.1998 5.22211L29.654 5.52201L30.9178 12.6467L29.6868 12.8855ZM33.0597 8.4119L35.7305 11.713L34.3281 11.9851L31.6971 8.67623L33.0597 8.4119Z"
        fill="white"
      />
      <path
        d="M48.2053 14.1587L50.4705 5.74501L51.5806 6.45723L49.6868 12.8833L49.7517 12.925L54.4259 8.28271L55.5258 8.98836L53.6846 15.453L53.7495 15.4947L58.3677 10.8117L59.4812 11.5261L53.3323 17.4481L52.27 16.7665L53.9477 10.4523L53.8931 10.4172L49.271 14.8424L48.2053 14.1587Z"
        fill="white"
      />
      <path
        d="M65.2317 27.0558C64.4643 27.5015 63.7284 27.7413 63.024 27.7752C62.3219 27.8078 61.6897 27.6607 61.1274 27.3338C60.5687 27.008 60.1212 26.5285 59.785 25.8953C59.4474 25.2596 59.2935 24.6065 59.3231 23.9359C59.3563 23.2663 59.5728 22.6278 59.9727 22.0204C60.3725 21.413 60.955 20.8871 61.72 20.4428C62.4874 19.9971 63.2221 19.758 63.9242 19.7255C64.6286 19.6915 65.2601 19.8375 65.8189 20.1633C66.3812 20.4901 66.8311 20.9713 67.1686 21.607C67.5049 22.2402 67.6564 22.8916 67.6232 23.5612C67.5936 24.2318 67.3789 24.8708 66.979 25.4782C66.5815 26.0843 65.999 26.6101 65.2317 27.0558ZM64.6492 25.959C65.2341 25.6193 65.6752 25.2365 65.9725 24.8106C66.2734 24.3858 66.438 23.9463 66.4662 23.4922C66.4981 23.0391 66.4023 22.6023 66.179 22.1817C65.9544 21.7588 65.6498 21.4417 65.2652 21.2305C64.883 21.0179 64.4392 20.9317 63.934 20.9719C63.4325 21.0131 62.8892 21.2035 62.3043 21.5432C61.7195 21.8829 61.2766 22.2652 60.9757 22.69C60.6784 23.1159 60.5138 23.5554 60.482 24.0085C60.4525 24.4602 60.55 24.8976 60.7746 25.3205C60.9979 25.741 61.3007 26.0576 61.683 26.2702C62.0688 26.4838 62.5126 26.57 63.0141 26.5288C63.5193 26.4886 64.0644 26.2987 64.6492 25.959Z"
        fill="white"
      />
      <path
        d="M71.4608 34.1827L71.4689 35.7045L65.0224 38.3882L65.0229 38.4856L71.4977 41.0938L71.5058 42.6157L63.1946 42.6642L63.1882 41.4711L69.2025 41.436L69.2021 41.3589L63.1869 38.9427L63.1816 37.9525L69.1747 35.4663L69.1743 35.3892L63.156 35.4243L63.1496 34.2312L71.4608 34.1827Z"
        fill="white"
      />
      <path
        d="M61.7992 48.7115L69.1824 52.5283L66.9556 57.2394L65.9966 56.7437L67.6875 53.1663L65.438 52.0034L63.8633 55.3349L62.9079 54.841L64.4826 51.5095L62.2223 50.341L60.5106 53.9624L59.5516 53.4666L61.7992 48.7115Z"
        fill="white"
      />
      <path
        d="M56.7282 68.8731L51.62 62.3168L52.4973 61.5693L59.4592 63.6376L59.5179 63.5876L55.7715 58.7792L56.726 57.9658L61.8342 64.5221L60.9508 65.2749L53.9808 63.2029L53.9221 63.2529L57.6734 68.0677L56.7282 68.8731Z"
        fill="white"
      />
      <path
        d="M45.9354 67.6179C46.2627 67.6099 46.5633 67.6824 46.8374 67.8354C47.1115 67.9911 47.3327 68.2022 47.501 68.4687C47.6667 68.7353 47.7531 69.0322 47.7604 69.3595C47.7678 69.6895 47.6947 69.9903 47.5411 70.262C47.3849 70.5364 47.1733 70.7568 46.9064 70.923C46.6396 71.0919 46.3425 71.1803 46.0153 71.1883C45.6853 71.1964 45.3846 71.1226 45.1132 70.9668C44.8392 70.8139 44.6193 70.6041 44.4537 70.3375C44.2854 70.0737 44.1976 69.7768 44.1902 69.4468C44.1829 69.1195 44.2574 68.8187 44.4136 68.5442C44.5671 68.2698 44.7773 68.0482 45.0442 67.8793C45.3084 67.7131 45.6054 67.626 45.9354 67.6179Z"
        fill="white"
      />
    </svg>
  );
};

export default VictoryCircularText;
