import React from "react";

const EyeOpenIcon = () => {
  return (
    <svg
      width="21"
      height="13"
      viewBox="0 0 21 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9399 6C19.7599 5.76 15.49 0 10.03 0C4.57997 0 0.309941 5.76 0.129941 6C-0.0400586 6.24 -0.0400586 6.55004 0.129941 6.79004C0.309941 7.03004 4.57997 12.79 10.03 12.79C15.49 12.79 19.7599 7.03004 19.9399 6.79004C20.1099 6.55004 20.1099 6.24 19.9399 6ZM10.03 11.47C6.01997 11.47 2.52994 7.63989 1.49994 6.38989C2.52994 5.13989 6.00997 1.32007 10.03 1.32007C14.05 1.32007 17.53 5.1499 18.56 6.3999C17.53 7.6499 14.06 11.47 10.03 11.47Z"
        fill="#6D6D6D"
      />
      <path
        d="M10.03 2.90991C8.11003 2.90991 6.54004 4.4699 6.54004 6.3999C6.54004 8.3199 8.11003 9.87988 10.03 9.87988C11.96 9.87988 13.52 8.3199 13.52 6.3999C13.52 4.4699 11.96 2.90991 10.03 2.90991ZM10.03 8.71997C8.75003 8.71997 7.70996 7.6799 7.70996 6.3999C7.70996 5.1099 8.75003 4.07007 10.03 4.07007C11.32 4.07007 12.3601 5.1099 12.3601 6.3999C12.3601 7.6799 11.32 8.71997 10.03 8.71997Z"
        fill="#6D6D6D"
      />
    </svg>
  );
};

export default EyeOpenIcon;
