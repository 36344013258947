import React from "react";

const DownArrowIcon = () => {
  return (
    <svg
      //   width="45"
      height="16"
      viewBox="0 0 45 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22.5 20.5L44.1506 0.25H0.849365L22.5 20.5Z" fill="#FF0071" />
    </svg>
  );
};

export default DownArrowIcon;
